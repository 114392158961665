
/**
 *
 * Plugin for show/hide charatters of password input in register and account page
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

(function ( $, _ ) { /* global _ */
	'use strict';

	/**
	 * @selector data-zg-role="password-unmask" The plugin start if there is the selector in the dom when the page load
	 */
	var selector = '[data-zg-role="password-unmask"]';


	// PASSWORD UNMASK CLASS DEFINITION
	// =============================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var PasswordUnmask = function ( element, options ) {
		this.$element = $( element );
		this.options = options;

		this.$target = $( this.options.target );
		this.$clone  = null;

		this.__setEventHandlers();
	};


	/**
	 * @param {string} [iconShow] Font awesome for button "show password"
	 * @param {string} [iconHide] Font awesome for button "hide password"
	 * @param {string} [activeClass] Class for the active button
	 * @param {string} target The id of input type password where show or hide password
	 */

	PasswordUnmask.DEFAULTS = {
		iconShow: 'fa-eye',
		iconHide: 'fa-eye-slash',
		activeClass: 'active',
		target: null
	};


	/**
	 *
	 * @private
	 */
	PasswordUnmask.prototype.__setEventHandlers = function () {
		this.$element.on( 'click.zg.passwordUnmask', (function ( e ) {
			e.preventDefault();

			this.__toggle();
		}).bind( this ) );
	};


	/**
	 *
	 * @private
	 */
	PasswordUnmask.prototype.__toggle = function () {
		if ( this.$element.hasClass( this.options.activeClass ) ) {
			
			this.$element
				.removeClass( this.options.activeClass )
				.find( '.fa' )
				.addClass( this.options.iconShow )
				.removeClass( this.options.iconHide );
				this.$target.parent().find('.btn-eye').prop('title', 'Show Password');
				this.$target.parent().find('.eye-btn').prop('title', 'Show Password');


			this.$target.prop( 'type', 'password' );

		} else {
			
			this.$element
				.addClass( this.options.activeClass )
				.find( '.fa' )
				.addClass( this.options.iconHide )
				.removeClass( this.options.iconShow );
			this.$target.parent().find('.btn-eye').prop('title', 'Hide Password');
			this.$target.parent().find('.eye-btn').prop('title', 'Hide Password');
			this.$target.prop( 'type', 'text' );


		}
	};


	/**
	 *
	 * @param {Object} options
	 * @private
	 */
	PasswordUnmask.prototype.__setOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );
	};


	// PASSWORD UNMASK PLUGIN DEFINITION
	// =================================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.passwordUnmask' );
			var options = _.extend( {}, PasswordUnmask.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.passwordUnmask', ( new PasswordUnmask( this, options ) ) );
			}
		} );
	}

	$.fn.zgPasswordUnmask             = Plugin;
	$.fn.zgPasswordUnmask.Constructor = PasswordUnmask;


	// PASSWORD UNMASK DATA-API
	// ========================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery, _ ));
