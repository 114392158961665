/**
 *
 * Plugin for Enhanced Ecommerce
 * - 1. Product Impressions
 * - 2. Product Clicks
 * - 3. Add / Remove from Cart
 * - 4. Promotion Impressions
 * - 5. Promotion Clicks
 * What is not included here is present in global/__google_tag_manager.html
 */

(function ( $ ) {
    'use strict';

    var dataLayer = window.dataLayer || [],
        contProductImpressions = 0;

    function parseCategoryNames( categories ){
        var categoryArray = _.map( categories, function( object ){ return object.name;} );
        return categoryArray.join(' / ');
    }

    /**
     * 1. Product Impressions - Every time a product is renedered
     *
     * Required event: zg.getProductInfo.productCreated
     * Required data: element, options, product
     */
    var impressions = [];
    var timeoutID;

    function pushImpressions( currency ){
        dataLayer.push({
            'event': 'productImpression',
            'ecommerce': {
                'currencyCode': currency, // Local currency is optional.
                'impressions': impressions
            }
        });
        impressions = [];
    }

    $( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
        if( product.price ) {
            if( timeoutID ){
                clearTimeout( timeoutID );
            }

            var categoryNames = parseCategoryNames(product.categories);
            var list;

            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }

            impressions.push(
                {
                    'name': product.name || '', // Name or ID is required.
                    'id': product.id || '',
                    'category': categoryNames || '',
                    'price': product.price.sell || '',
                    'brand': product.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
                    'list': list,
                    'position': contProductImpressions || ''
                }
            );
            timeoutID = setTimeout( pushImpressions, 1500, product.price.currency );
        }
    } );


    /**
     * 2. Product Clicks
     *
     * To track the link, be sure that all link tag have the attr data-zg-role="product-link"
     */
    $( document ).on( 'click', '[data-zg-role="product-link"]', function(){

        if( this.attributes.href ) {

            var parent = $( this ).closest( '[data-zg-role="product"]' ).data( 'zg.product' );
            var productObj = parent.product;
            var categoryNames = parseCategoryNames( productObj.categories );
            var position = $( this ).closest( '[data-zg-role="product"]' ).data( 'position' );

            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'products': [{
                            'name': productObj.name || '', // Name or ID is required.
                            'id': productObj.id || '',
                            'brand': 'Nutmeg',
                            'category': categoryNames || '',
                            'variant': '',
                            'position': position || ''
                        }]
                    }
                }
            });
        }
    });

    /**
     * 3. Add / Remove from Cart
     *
     * Required event: zg.product.addedToCart
     * Required data: status, productObj, dataSent
     */
    $( document ).on( 'zg.product.addedToCart', function ( e, status, productObj, dataSent ) {

        dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': productObj.price.currency,
                'add': { // 'add' actionFieldObject measures.
                    'products': [{ //  adding a product to a shopping cart.
                        'name': productObj.name || '',
                        'id': productObj.id || '',
                        'price': productObj.price.sell || '',
                        'brand': productObj.brand || '',
                        'variant': productObj.skus[dataSent.sku].external_id || dataSent.sku || '',
                        'category': window.JS_TRANSLATIONS['category.name'],
                        'quantity': dataSent.quantity || ''
                    }]
                }
            }
        });

    } );



    $( document ).on( 'click', '[data-zg-role="add-to-wishlist"]', function() {

        var product_id = $(this).closest( '[data-zg-role="product"]' ).data( 'id' );
        var name = $(this).closest( '[data-zg-role="product"]' ).data( 'name' );

        dataLayer.push({
            'event': 'add_to_wishlist',
            'id': product_id.toString() || '',
            'name': name || ''
        });
    } );


    /**
     * Required event: zg.product.removedFromCart
     * Required data: type, status, data
     */
    $( document ).on( 'zg.product.removedFromCart', function ( e, type, status, data ) {

        if( type === 'cart' ){

            console.log(data);

            //var categoryNames = parseCategoryNames( data.categories );

            // Measure the removal of a product from a shopping cart.
            dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'remove': { // 'remove' actionFieldObject measures.
                        'products': [{ //  removing a product to a shopping cart.
                            'name': data.name || '',
                            'id': data.product_id || '',
                            'price': data.price || '',
                            'brand': 'Nutmeg',
                            'variant': data.sku || '',
                            'category': data.categories || '',
                            'quantity': data.quantity || ''
                        }]
                    }
                }
            });
        }

    } );

    /**
     * For Cart page
     * When a product is removed from cart we are submitting a form. Also when we submitting a form we should trigger zg.product.removedFromCart
     */
    $( document ).on( 'submit', '[data-zg-role="list-item-remove"]', function () {
        var $this = $(this),
            data  = $this.data();

        var dataToSend = {
            'product_id': 	data.id || 0,
            'sku': 			data.sku || 0,
            'quantity': 	data.quantity || 1,
            'name': 		data.name || '',
            'price': 		data.price || 0,
            'categories':	data.categories || ''
        };

        $( document ).trigger( 'zg.product.removedFromCart', ['cart', null, dataToSend] );
    } );

    /**
     * 4. Promotion Impressions
     *
     * To track the promo present in page, be sure that all promo div have the attr data-zg-role="promotion"
     */
    $( '[data-zg-role="promotion"]' ).each(function( index, element ) {

        dataLayer.push({
            'ecommerce': {
                'promoView': {
                    'promotions': [
                        {
                            'id': element.dataset.promoId || '', // Name or ID is required.
                            'name': element.dataset.promoName || '',
                            'position': element.dataset.promoPosition || ''
                        }]
                }
            }
        });
    });

    /**
     * 5. Promotion Clicks
     *
     * To track the promo link, be sure that all link tag have the attr data-zg-role="promotion-link"
     * Required attr: data-promo-id="x", data-promo-name="x", data-position='x'
     */
    $( document ).on( 'click', '[data-zg-role="promotion-link"]', function() {
        if( this.attributes.href ){
            dataLayer.push({
                'event': 'promotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [
                            {
                                'id': this.dataset.promoId || '', // Name or ID is required.
                                'name': this.dataset.promoName || '',
                                'position': this.dataset.promoPosition || ''
                            }]
                    }
                }
            });
        }
    });

    ///Login
    $( document ).on('click','[data-zg-role="checklogin"]',function(e){
        e.preventDefault();

        dataLayer.push({
            'event': 'login_success'
        });
        $(this).parents('form').submit();
    });

    ///Register
    $( document ).on('click','[data-zg-role="check_register"]',function(e){
        e.preventDefault();

        dataLayer.push({
            'event': 'registration_success'
        });
        $(this).parents('form').submit();
    });

}( jQuery ));