/**
 * zg-refunds.js
 *
 * Plugin for updating the order page when refunds are processed through salesforce
 *
 * @author: @author: Kevin Prunty <kprunty [at] koooomo [dot] com>
 *
 */

(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector The plugin start if there is the selector in the dom when the page load
     */

    var selector = '[data-zg-role="refunds"]';

    var DEFAULTS = {
        hasRefunds : false,
        refundType : false,
        refunds : false,
        originalTotal : false,
        updatedTotal : 0,
        refundedLines : 0,
        totalRefunded : 0
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var Refunds = function ( element, options ) {

        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.__init();
    };

    Refunds.prototype.__init = function () {

        if(!this.options.refunds) {return;}

        // get the refunded lines
        this.options.refundedLines = this.__getRefundedLines();
        // get the total amount refunded
        this.options.totalRefunded = this.__getTotalRefunded();
        // update the markup on lines - add the qty refunded label
        this.__showQuantityRefunded();

        if(this.options.totalRefunded){
            this.options.updatedTotal = this.options.originalTotal - this.options.totalRefunded;
            this.__updateTotals();
        }

    };

    Refunds.prototype.__getRefundedLines = function (){
        var refundedLines = {};

        this.options.refunds.forEach(function(refund){
            refund.orderLines.forEach(function(line){
                if(refundedLines.hasOwnProperty(line.lineId)){
                    refundedLines[line.lineId] = refundedLines[line.lineId] + line.qtyRefunded;
                    return;
                }
                refundedLines[line.lineId] = line.qtyRefunded;
                return;
            });
        });

        return refundedLines;
    };

    Refunds.prototype.__getTotalRefunded = function(){
        // add the totals for all of the refunds
        return this.options.refunds.reduce(function(total, refund){
            return total += refund.refundAmount;
        }, 0);
    };

    Refunds.prototype.__updateTotals = function (  ) {
        var $target = this.$element.next('table').find('tfoot .lead'),
            $refundedRow = $('<tr class="lead"><th class="text-left" colspan="2">refunded:</th><th class="text-right">&pound; ' +
                this.options.totalRefunded.toFixed(2) + '</th></tr>'),
            $updatedTotalRow = $('<tr class="lead"><th class="text-left" colspan="2">updated total:</th><th class="text-right">&pound; ' +
                this.options.updatedTotal.toFixed(2) + '</th></tr>');

        $updatedTotalRow.insertAfter($target);
        $refundedRow.insertAfter($target);
        $target.find('.text-left').text('original total:');
    };

    Refunds.prototype.__showQuantityRefunded = function(){
        var that = this;

        // add an attribute to show which lines have been refunded
        $('[data-lineId]').each(function(){
            var $line = $(this),
                lineid = $line.data('lineid'),
                qtyRefunded = that.options.refundedLines[lineid] || false;

            if(!qtyRefunded){ return;}

            $line.find('.order_label').append('<p class="qty-refunded"><strong>qty refunded:</strong> ' + qtyRefunded  + '</p>');
            $line.data('isRefund', 'true');
            $line.addClass('has-refund');
        });
    };

    // Refunds PLUGIN DEFINITION
    // =========================
    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.refunds' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.refunds', new Refunds( this, options ) );
            }
        } );
    }

    $.fn.zg_refunds             = Plugin;
    $.fn.zg_refunds.Constructor = Refunds;

    //START PLUGIN
    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );


}).call( this, jQuery, _ );