(function ($) {
    $(function(){
        //convert CMs to Inches, store original and new in separate elements, hide inches

        // return early if we do not have a WWIn object
        if(typeof WWIn !== 'object') {return;}

        var Instructions = {};
        var Data = {};

        Instructions.women = WWIn.fields;
        Instructions.men = MWIn.fields;
        Instructions.children = CWIn.fields;

        Data.women = WWSG.fields;
        Data.men = MWSG.fields;
        Data.children = CWSG.fields;
        Data.baby = BWSG.fields;

        for(key in Data){
            
            contentParts = [];

            for(section in Data[key]) {
                if(section.indexOf('Title') > 1) {
                    contentSemantic = section.replace(" Title","");
                    if('undefined' == typeof contentParts[contentSemantic]) {
                        contentParts[contentSemantic] = {};
                    }
                    contentParts[contentSemantic].title = Data[key][section];
                    
                }
                if(section.indexOf('Table') > 1) {
                    contentSemantic = section.replace(" Table","");
                    if('undefined' == typeof contentParts[contentSemantic]) {
                        contentParts[contentSemantic] = {};
                    }
                    contentParts[contentSemantic].table = Data[key][section];
                    
                }
                if(section.indexOf('Units') > 1) {
                    contentSemantic = section.replace(" Units","");
                    if('undefined' == typeof contentParts[contentSemantic]) {
                        contentParts[contentSemantic] = {};
                    }
                    contentParts[contentSemantic].units = Data[key][section];
                } else {
                    contentParts[contentSemantic].units = false
                }
            }

            content = "";
            


	   for(sizeType in contentParts) {
                if(contentParts[sizeType].units) {
                    //line pulls in the name and the conversion to cm 2 inches
                    contentParts[sizeType].title = contentParts[sizeType].title.replace(/\s+/g, "");
                    content = content + "<h3 class ='"+contentParts[sizeType].title+"' > "+contentParts[sizeType].title+" <span class='conversion-links'>(<a href='#' class='convert' data-from='"+contentParts[sizeType].units[0]+"' data-target='"+key+"."+sizeType+"'>"+contentParts[sizeType].units[0]+"</a>)</span> </h3>";
                    //this line pulls the content
                    content = content + "<div class='data-table "+contentParts[sizeType].title+" '  data-from='"+contentParts[sizeType].units[0]+"' id ='"+contentParts[sizeType].title+"'  data-source='"+key+"."+sizeType+"'>"+contentParts[sizeType].table+"</div>";
                //this is for the baby sizes
                } else {
                    contentParts[sizeType].title = contentParts[sizeType].title.replace(/\s+/g, "");
                    content = content + "<h3 class ='"+contentParts[sizeType].title+"' > "+contentParts[sizeType].title+" </h3>";
                    content = content + "<div class='data-table "+contentParts[sizeType].title+" ' id ='"+contentParts[sizeType].title+"' >"+contentParts[sizeType].table+"</div>";
                }
            }


            var tab = "<li id='"+key+"-size-guide'><span>"+key+"</span></li>";
            var tabContent = "<div class='tab' id='"+key+"-size-guide-tab'>"+content+"</div>";

            $("#size_guide ul.tab-nav").append(tab);
            $("#size_guide div.tab-content").append(tabContent);
           
    
            // var tab = " <div class='holder'>      <div class='tab' id='"+key+"-size-guide-tab'>" +content+ "</div>   </div> ";
            
            // //var tabContent = "<div class='tab' id='"+key+"-size-guide-tab'>"+content+"</div> <li id='"+key+"-size-guide'><span>"+key+"</span></li> </div>";
            
            
            // $("#size_guide ul.tab-nav").append(tab);
           
            // //$("#size_guide div.tab-content").append(tabContent);


            // var tab = " <div class='holder'>      <div class='tab' id='"+key+"-size-guide-tab'>" +content+ "</div>   </div> ";
            
            // //var tabContent = "<div class='tab' id='"+key+"-size-guide-tab'>"+content+"</div> <li id='"+key+"-size-guide'><span>"+key+"</span></li> </div>";
            
            
            // $("#size_guide ul.tab-nav").append(tab);
           
            // //$("#size_guide div.tab-content").append(tabContent);

        }
        $('<div/>', {
            id: 'sizeguidecloser',
            "class": 'some-class', 
        }).appendTo('#size_guide');


        $("#size_guide ul.tab-nav li:first").addClass('active')
        $("#size_guide div.tab-content div.tab:first").addClass('active');


        //*
        $("#size_guide").on('click','#sizeGuidePage ul.tab-nav li',function(){
            var tab_id = $(this).attr('id');
            
            $('#sizeGuidePage ul.tab-nav li').removeClass('active');
            $(this).addClass('active');
            $('#sizeGuidePage.tab-container div.tab').removeClass('active');
            $('#sizeGuidePage.tab-container div#' + tab_id + '-tab').addClass('active');
        

        
        });
        $('#sizeGuidePage ul.tab-nav li:first-child').trigger('click'); 
        //*/

        $("body").on('click',"a[data-reveal='os-off-canvas']",function(){
            var idSelector = $(this).attr('data-target');
            $(idSelector).animate({"right":"0"},500)
        });

        $("body").on('click',".close[data-dismiss='os-off-canvas']",function(){

            var target = $(this).closest("div[data-role='os-off-canvas']");
            var offset = ($(target).width() + 100) * -1;

            $(target).animate({"right":offset},500);


        });

        Cm2Inch = function (cm) {
            var i = cm / 2.54;
            var ir = i * 10;
            var ir = Math.round(ir);
            var ir = ir / 10;

            return ir;
        }

        //$(".convertInch").each(function(){
        $(".data-table[data-from='cm'] td").each(function(){
            var c = $(this).text();
            var s = parseFloat(c);
            var sc = s+"";
            var p = /([0-9]*)-([0-9]*)/;
            
            if(c == sc) {
                //single value
                var ir = Cm2Inch(s);
                $(this).empty().append("<span class='cm'>"+c+"</span><span class='inch'>"+ir+"</span>");
            } else {
                //ranges
                var r = p.exec(c); //test regex
                if(!!r && r[0] == c) { //ensire we are working on what we expect
                    var f_cm = r[1]; //from
                    var t_cm = r[2]; //to

                    var f_in = Cm2Inch(f_cm);
                    var t_in = Cm2Inch(t_cm);

                    $(this).empty().append("<span class='cm'>"+f_cm+"-"+t_cm+"</span><span class='inch'>"+f_in+"-"+t_in+"</span>");
                }
            }
            
            $(".data-table[data-from='cm'] td .inch").hide();
        });

        showInches = function() {
            $(".data-table[data-from='cm'] td .cm").hide(); $(".data-table[data-from='cm'] td .inch").show();
        }

        showCms = function() {
            $(".data-table[data-from='cm'] td .inch").hide(); $(".data-table[data-from='cm'] td .cm").show();
        }

        $("a.convert").each(function(){
            if($(this).attr('data-from') == 'cm') {
                $(this).after(" | <a href='#' class='convert' data-from='inch'  data-target='men.Menswear Size'>inch</a>");
                $(this).css({"text-decoration":"underline"});
            }
        });

        $("#sizeGuidePage").on('click',".convert",function(e){
            e.preventDefault();

            $("a.convert").css({"text-decoration":"none"});

            if($(this).attr('data-from') == 'cm') {
                $("a.convert[data-from='cm']").css({"text-decoration":"underline"});
                showCms();
            }
            if($(this).attr('data-from') == 'inch') {
                $("a.convert[data-from='inch']").css({"text-decoration":"underline"});
                showInches();
            }

            return false;
        });


        var link = $(".main_cat a").attr("href");
        link = link.split("/").pop();
           
    console.log(link);
        if (link == "kids"){
            console.log("hello");
            $("#children-size-guide").trigger('click');  
            var speciallink = $(".breadcrumb li:eq(3) a").attr("href");
            //this is picking up the thrid one    ^^^ 
            if(speciallink !== null){
                speciallink = speciallink.split("/").pop();
            
                if(speciallink == "girls-leggings"){
                    $('#size_guide').animate({ scrollTop: 1000}, 800, 'swing');
                } 
                if(speciallink == "footware"){
                    $('#size_guide').animate({ scrollTop: 1000}, 800, 'swing');
                    console.log("footware");
                } 
                
            }
        }
    
        if (link == "baby-clothes-accessories"){
            $('#baby-size-guide').trigger('click');     
            var speciallink = $(".breadcrumb li:eq(3) a").attr("href");
            //this is picking up the thrid one    ^^^ 
            if(speciallink !== null){
                speciallink = speciallink.split("/").pop();
                
                if(speciallink == ""){
                    $('#size_guide').animate({ scrollTop: 1000}, 800, 'swing');
                } 
                
            }   
    
        }
        if (link == "men"){
            $('#men-size-guide').trigger('click'); 
            var speciallink = $(".breadcrumb li:eq(3) a").attr("href");
            //this is picking up the thrid one    ^^^ 
            if(speciallink !== null){
                speciallink = speciallink.split("/").pop();
            
                if(speciallink == "mens-socks"){
                    $('#size_guide').animate({ scrollTop: 1000}, 800, 'swing');
                } 
                
                if(speciallink == "nightwear-slippers"){
                    $('#size_guide').animate({ scrollTop: 1000}, 800, 'swing');
                } 
                
            }           
        }
    
        if (link == "women"){
    
    
            var speciallink = $(".breadcrumb li:eq(3) a").attr("href");
            //this is picking up the thrid one    ^^^ 
            if(speciallink !== null){
                speciallink = speciallink.split("/").pop();
              
                 if(speciallink == "womens-bras"){
                    $('#size_guide').animate({ scrollTop: 1000}, 800, 'swing');    
                 } 
                 if(speciallink == "womens-socks-tights"){
                     $('#size_guide').animate({ scrollTop: 800}, 800, 'swing');
                }
                if(speciallink == "womens-slippers"){
                    $('#size_guide').animate({ scrollTop: 600}, 800, 'swing');
               }
                
            }
        }
        
    });


            
   

    
})(jQuery);
