/**
 *
 * Sends an email to with the current url
 *
 * @author David Pocina <dpocina[at]zerogrey[dot]com>
 */

/**
 * @event document#click.zg.sendToFriend.data-api Email with the current product url sent
 * @type {null}
 */

(function ( $ ) { /* global JS_TRANSLATIONS */
    'use strict';

    /**
     * @selector data-zg-role="send-to-friend" The plugin start on click to selector (submit button)
     */
    var selector         = '[data-zg-role="send-to-friend"]';

    // SENDTOFRIEND CLASS DEFINITION
    // =============================

    var SendToFriend = function ( element, options ) {
        this.$form = $(element).closest( 'form' );
        this.options = options;
    };


    /**
     * @param {string} [pid] Input hidden with product Id
     * @param {string} [name] Input with name of the sender
     * @param {string} [email] Input with email of the sender
     * @param {string} [message] Textarea with message you want to send
     */

    SendToFriend.DEFAULTS = {
        pid: '[data-zg-role="send-to-friend-pid"]',
        name: '[data-zg-role="send-to-friend-name"]',
        email: '[data-zg-role="send-to-friend-email"]',
        message: '[data-zg-role="send-to-friend-message"]'
    };


    /**
     *
     */
    SendToFriend.prototype.responseMsg = function ( type, msg ) {
        $(document).trigger( type, [{
            eventType: 'sent-to-friend',
            message: msg
        }]);
    };


    /**
     *
     */
    SendToFriend.prototype.send = function () {
        if (
            this.$form.data('zgValidator') &&
                !this.$form.data('zgValidator').validate()
            ) {
            return false;
        }

        var that = this,
            url = window.makeUrl({module: 'eshop', action: 'sendProductByEmailTo'}),
            request = {
                product_id : $(this.options.pid, this.$form).val(),
                name       : $(this.options.name, this.$form).val(),
                send_to    : $(this.options.email, this.$form).val(),
                message    : document.location.href +
                    '\n\n\n' +
                    $(this.options.message, this.$form).val()
            };

        $.ajax({
            type : 'post',
            url  : url,
            data : request,

            success: function ( response ) {
                if ( response.status === 'success' ) {
                    $(that.options.email, that.$form).val('');

                    that.responseMsg( 'zg-notification', response.message );
                } else  {
                    that.responseMsg( 'zg-error', (response && response.message ? response.message : JS_TRANSLATIONS.genericErrorMsg) );
                }
            },

            error: function ( response ) {
                that.responseMsg( 'zg-error', (response && response.message ? response.message : JS_TRANSLATIONS.genericErrorMsg) );
            }
        });
    };


    // SENDTOFRIEND PLUGIN DEFINITION
    // ==============================

    function Plugin( option ) {
        return this.each(function () {
            var $this  = $(this);
            var data   = $this.data('zg.sendToFriend');
            var options = $.extend({}, SendToFriend.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option);

            if (!data) {
                $this.data('zg.sendToFriend', (data = new SendToFriend(this, options)));
            }

            data.send();
        });
    }

    $.fn.zg_sendToFriend             = Plugin;
    $.fn.zg_sendToFriend.Constructor = SendToFriend;


    /**
     * @method document
     * @listen document#click.zg.sendToFriend.data-api Click on selector of the plugin
     */

    $(document).on('click.zg.sendToFriend.data-api', selector, function (e) {
        Plugin.call( $(this));

        e.preventDefault();
    });

})( jQuery );