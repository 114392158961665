/**
 *
 * Click and Collect Bing javascript
 *
 * @author Fabio Polizzi  <fpolizzi[at]kooomo[dot]com>
 *
 */

/* global _, JS_TRANSLATIONS, handlebarsTemplates, zgPost */

(function ( $, _ ) {
    'use strict';

    // Establish the root object ('window' in the browser)
    var root = this;

    /**
     * @selector
     */
    var SELECTOR = '[data-zg-role="storeLocator"]';

    var morrisonsApi = 'kxBdM2chFwZjNvG2PwnSn3sj6C53dLEY';
    var bingApi = 'Ag00iheqslugEVbwF43XtJCoY0tAUdcyXd8XS3oA4l389lpCxFj2vYXknu_B_0ua';


    var DEFAULTS = {
        openModalBtn: '[data-zg-role="searchStoreBtn"]',
        searchInput: '[data-zg-role="listFilter"]',
        storeTemplate: 'store',
        locationTemplate: 'location',
        storeList: '[data-zg-role="store-list"]',
        selectStoreBtn: '[data-zg-role="selectStore"]',
        selectStore: '[data-zg-role="selectStoreSelect"]',
        selectedStoreName: '[data-zg-role="selectedStoreName"]',
        errorDiv: '.error-div',
        currentBtn: '[data-zg-role="searchByCurrent"]',
        map: '#myMap',
        chooseLocation: '[data-zg-role="choose-location"]',
        chooseBtn: '[data-zg-role="choose"]',
        hiddenStores: false,
        noCollectionStores: false
    };

    var DEFAULT_REQUEST = {
        //merchantId: null,
        //language: null,
        type:      'Stores',
        //contentId: null,
        isPublished: 1,
        limit:       1,
        //clearCache: null,
        //contentLinkedToContentId: null,
        //contentLinkedToProductId: null,
        //contentLinkedToCategoryId: null,
        //contentLinkedToPromotionId: null,
        getFilters: null,
        filterBy: null,
        //filterByOperator: false,
        //orderByFieldName: null,
        //orderDirection:   null,
        //forceArray:       null,
        //fakeCurDate: null
    };

    // GETUPSACCESSPOINTS CLASS DEFINITION
    // ===================================

    /**
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var StoresMorrison = function ( element, options ) {
        this.$element = $( element );
        this.options  = options;

        this.$openModalBtn = this.$element.find( this.options.openModalBtn );
        this.$searchInput = this.$element.find( this.options.searchInput );
        this.$storeList = this.$element.find( this.options.storeList );
        this.$errorDiv = this.$element.find( this.options.errorDiv );
        this.$map = this.$element.find( this.options.map );
        this.$chooseLocation = this.$element.find( this.options.chooseLocation );
        this.$selectedStoreName = $( this.options.selectedStoreName );

        this.requestUrl = root.makeUrl( {module: 'eshop', manager: 'eshop', action: 'getContentCMSWithFilters'} );
        this.request = _.clone( DEFAULT_REQUEST );

        this.__init();
        this.__setEventHandlers();
    };

    StoresMorrison.prototype.__init = function () {

        this.__setNoCollectionStores();
        this.__setHiddenStores();

        if( this.options.type == 'modal' ){
            this.$map.hide();
            this.$errorDiv.hide();
            this.$chooseLocation.hide();
        }
        else{
            this.$errorDiv.hide();
            this.$chooseLocation.hide();
            //this.$selectedStoreName.hide();
            this.__callMorrisonsApi( 53.806372, -1.713746);
        }
    };

    StoresMorrison.prototype.__setHiddenStores = function () {
        var hiddenStoreIds = this.options.hiddenStores;
        if(!hiddenStoreIds){
            this.options.hiddenStoreIds = false;
            return;
        }

        this.options.hiddenStoreIds = hiddenStoreIds.split('|').filter( function(storeId) {
            return storeId > 0;
        });

        return;
    };


    StoresMorrison.prototype.__setNoCollectionStores = function ( ) {
        var noCollectionStoreIds = this.options.noCollectionStores;
        if(!noCollectionStoreIds){
            this.options.noCollectionStoreIds = false;
            return;
        }

        this.options.noCollectionStoreIds = noCollectionStoreIds.split('|').filter( function(storeId) {
            return storeId > 0;
        });
        console.log(this.options.noCollectionStoreIds);
        return;
    };

    StoresMorrison.prototype.__printMap = function ( latitude, longitude, stores ) {

        var that = this;

        var map = null, infobox, dataLayer;

        this.$map.show();

        var maxDistance = 0;

        $.each( stores, function( key, value ) {
            if ( maxDistance < value.distance ) maxDistance = value.distance;
        });

        var zoomMap = 11;

        if(maxDistance > 55000){
            zoomMap = 6;
        }else if(maxDistance > 45000){
            zoomMap = 7;
        }else if(maxDistance > 35000){
            zoomMap = 8;
        }else if(maxDistance > 25000){
            zoomMap = 9;
        }else if(maxDistance > 15000){
            zoomMap = 10;
        }


        var map = new Microsoft.Maps.Map( that.options.map , {
            credentials: bingApi,
            center: new Microsoft.Maps.Location(latitude, longitude),
            mapLib: 'https://ecn.dev.virtualearth.net/mapcontrol/mapcontrol.ashx?v=7.0',
            zoom: zoomMap
        });



        dataLayer = new Microsoft.Maps.EntityCollection();
        map.entities.push(dataLayer);

        //var infoboxLayer = new Microsoft.Maps.EntityCollection();
        //map.entities.push(infoboxLayer);

        infobox = new Microsoft.Maps.Infobox(new Microsoft.Maps.Location(0, 0), { visible: false, offset: new Microsoft.Maps.Point(0, 20) });
        //infoboxLayer.push(infobox);
        infobox.setMap(map);

        var center = map.getCenter();

        var d = new Date();
        var weekday = new Array(7);
        weekday[0] =  "sun";
        weekday[1] = "mon";
        weekday[2] = "tue";
        weekday[3] = "wed";
        weekday[4] = "thu";
        weekday[5] = "fri";
        weekday[6] = "sat";

        var n = weekday[d.getDay()];

        console.log(n);
        console.log(stores);

        that.$storeList.html('');

        $.each( stores, function( key, value ) {

            var miles = value.distance / 1609;
            miles = miles.toFixed(2);
            miles = miles.toString();

            var number = key+1;
            number = number.toString();

            value.miles = miles;
            value.number = number;

            var opentoday = value.openingTimes[n].open;
            opentoday = opentoday.toString().substring(0, opentoday.length - 3);

            var closetoday = value.openingTimes[n]['close'];
            closetoday = closetoday.toString().substring(0, closetoday.length - 3);

            value.opentoday = opentoday;
            value.closetoday = closetoday;
            value.noCollectionStore = that.options.noCollectionStoreIds && that.options.noCollectionStoreIds.includes(value.name.toString());

            var $item = $( handlebarsTemplates.render( that.options.storeTemplate, value ) );

            that.$storeList.append( $item );

            var pin = new Microsoft.Maps.Pushpin( new Microsoft.Maps.Location(value.location.latitude, value.location.longitude) , {
                title: '',
                subTitle: '',
                icon: 'https://my.morrisons.com/storefinder/images/listingPushPin.svg',
                text: number
            });

            pin.Title = "";
            pin.Description = value.storeName;

            Microsoft.Maps.Events.addHandler(pin, 'click', pushpinClicked);
            //Add the pushpin to the map
            map.entities.push(pin);

        });

        function pushpinClicked(e) {

            console.log('click');
            if (e.targetType == 'pushpin') {
                infobox.setLocation(e.target.getLocation());
                infobox.setOptions({ visible: true, title: e.target.Title, description: e.target.Description });
            }
        }

    }


    StoresMorrison.prototype.__callMorrisonsApi = function ( latitude, longitude ) {

        var that = this;

        $.ajax( {
            url:      'https://api.morrisons.com/location/v2//stores',
            type: "get",
            data: {
                apikey: morrisonsApi,
                distance: 50000,
                lat: latitude,
                limit: 10,
                lon: longitude,
                offset: 0,
                storeformat: 'supermarket'
            },

            beforeSend: function () {
            }.bind( this ),

            success: function ( response ) {
                var stores = false;
                if(response.stores[0]){
                    stores = response.stores;

                    if(that.options.hiddenStoreIds) {
                        stores = response.stores.filter(function(store){
                            // hide stores that are not published
                            return !that.options.hiddenStoreIds.includes(store.name.toString());
                        });
                    }
                    that.__printMap( latitude, longitude, stores);
                }else{
                    that.$errorDiv.html('No stores found, please try again');
                    that.$errorDiv.show();
                }

            }.bind( this ),

            error: function ( response ) {
            }.bind( this ),

            complete: function () {
            }.bind( this )
        } );
    };

    StoresMorrison.prototype.__createMap = function ( zipCode, city ) {


        var that = this;
        var country = '';
        if (city) country = 'UK';


        $.ajax( {

            url:      'https://dev.virtualearth.net/REST/v1/Locations',
            type: "get",
            data: {
                countryRegion: country,
                adminDistrict: '',
                locality: city,
                postalCode: zipCode,
                addressLine: '',
                userLocation: '',
                userIp: '',
                usermapView: '',
                includeNeighborhood: '',
                maxResults: '',
                key: bingApi
            },

            beforeSend: function () {
            }.bind( this ),

            success: function ( response ) {
                if(response.resourceSets[0].resources[0] && (response.resourceSets[0].resources[0].point.coordinates[0] != 53.9438362121582 && response.resourceSets[0].resources[0].point.coordinates[1] != -2.5505640506744385)){
                    if(response.resourceSets[0].estimatedTotal > 1) {
                        that.__chooseLocation( response.resourceSets[0].resources );
                    }else{
                        var latitude = response.resourceSets[0].resources[0].point.coordinates[0];
                        var longitude = response.resourceSets[0].resources[0].point.coordinates[1];
                        that.__callMorrisonsApi( latitude, longitude);
                    }
                }else{
                    if ( zipCode ){
                        that.__createMap( '', zipCode );
                    }else{
                        this.$errorDiv.html('Address not found, please enter a city or a cap');
                        this.$errorDiv.show();
                    }
                }
            }.bind( this ),

            error: function ( response ) {
            }.bind( this ),

            complete: function () {
            }.bind( this )
        } );

    };

    StoresMorrison.prototype.__chooseLocation = function ( locations ) {
        var that = this;
        that.$chooseLocation.find('ul').html('');
        $.each( locations , function( key, value ) {
            var $item = $( handlebarsTemplates.render( that.options.locationTemplate, value ) );
            that.$chooseLocation.find('ul').append( $item );
        });
        that.$chooseLocation.show();
    };

    StoresMorrison.prototype.__getCurrentLocation = function () {
        var that = this;
        navigator.geolocation.getCurrentPosition(locationHandler);
        function locationHandler(position)
        {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            that.__callMorrisonsApi( lat, lng);
        }
    };


    StoresMorrison.prototype.__getStoreFromCms = function ( storeId, triggerModal ) {

        this.request.filterBy = 'StoreId['+ storeId +']';

        this.latestRequest = $.ajax( {
            type:     'GET',
            url:      this.requestUrl,
            dataType: 'json',
            data:     this.request,

            beforeSend: (function () {
            }).bind( this ),

            success: (function ( response ) {
                console.log(response);

                for (var key in response.contents) {
                    $( document ).trigger( 'zg.storeLocator.selectedMarker', [storeId, response.contents[key]] );

                    if( triggerModal !== false ) {
                        $(SELECTOR).modal('toggle');
                    }
                }

            }).bind( this ),

            error: (function ( response ) {

            }).bind( this ),

            complete: (function () {

            }).bind( this )
        } );


        return storeId;
    };




    // EVENT HANDLERS
    // ------------------------------------------------------------------------


    /**
     *
     * @private
     */
    StoresMorrison.prototype.__setEventHandlers = function () {
        var that = this;

        this.$openModalBtn.on( 'click', (function ( e ) {
            e.preventDefault();
            that.$errorDiv.hide();
            that.$chooseLocation.hide();
            var value = that.$searchInput.val();
            that.__createMap( value,'' );
        }).bind( this ) );

        $( document ).on( 'click', this.options.selectStoreBtn, (function ( e ) {
            e.preventDefault();
            var storeId = $(this).attr('href');
            var triggerModal = $(this).data('triggerModal');
            var storeFromCms = that.__getStoreFromCms( storeId, triggerModal );
        }));

        $( document ).on( 'change', this.options.selectStore, (function () {
            var storeId = $(this).val();
            var storeFromCms = that.__getStoreFromCms( storeId, false );
        }));

        $( document ).on( 'click', this.options.currentBtn, (function ( e ) {
            e.preventDefault();
            that.__getCurrentLocation();
        }));

        $( document ).on( 'click', this.options.chooseBtn, (function ( e ) {
            e.preventDefault();
            var latitude = $(this).data('lat');
            var longitude = $(this).data('long');
            that.__callMorrisonsApi( latitude, longitude);
            that.$chooseLocation.hide();
        }));
    };

    // GETUPSACCESSPOINTS PLUGIN DEFINITION
    // ====================================

    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.storesMorrison' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, DEFAULTS, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.storesMorrison', (data = new StoresMorrison( this, options )) );
            }
        } );
    }

    $.fn.storesMorrison             = Plugin;
    $.fn.storesMorrison.Constructor = StoresMorrison;


    // GETUPSACCESSPOINTS DATA-API
    // ===========================

    // default product - called on page load
    $( function () {
        if( ZG_CONFIG.collectInStoreActive == true ) {
            $(SELECTOR).each(function () {
                Plugin.call($(this));
            });
        }
    } );

}.call( this, jQuery, _ ));
