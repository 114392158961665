$(document).ready(function() {
// this is the only custom js file
// the rest are third party dependendcies
// no semicolons needed here, this is 2018
// good indenting will suffice

    $( window ).resize(function() {
        if($(window).width() > 768){
            $( "#wrapper" ).removeClass( "toggled" );
        }
    });


    // $("#qty").on("keypress keyup blur",function (event) {    
    //    $(this).val($(this).val().replace(/[^\d].+/, ""));
    //     if ((event.which < 48 || event.which > 57)) {
    //         event.preventDefault();
    //     }
    // });

    $("#qty").on("keypress keyup blur",function (event) {    
       $(this).val($(this).val().replace(/[^\d].+/, ""));
        if (event.which == 46 && event.which > 31 && (event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
    });


    stockTimer = setTimeout(checkStock, 1000);
    function checkStock(){
        if((jQuery('.product-details').length) && jQuery('[data-zg-role="option-selector"]').length){
            var stockData = jQuery('.product-details').attr('data-product');
            if(typeof(stockData) !== 'undefined'){
                stockData = jQuery.parseJSON(stockData);
                jQuery('[data-zg-role="option-selector"]').find('a').each(function(){

                    var disabledBtn= 1;

                    var dataValue = jQuery(this).attr('data-value');
                    for(key in stockData.skus){
                        for(keys in stockData.skus[key].options){
                            if(stockData.skus[key].options[keys] == dataValue){
                                if(stockData.skus[key].quantity){
                                    disabledBtn = 0;


                                }
                            }
                        }
                    }

                    if (disabledBtn == 1){
                        jQuery(this).addClass('lineThrough');
                    }

                });
                if(jQuery('[data-zg-role="option-selector"]').find('a').length == jQuery('.lineThrough').length){
                    jQuery('.availability').text('Out of stock');
                }
            }
        }
        else{
            stockTimer = setTimeout(checkStock, 1000);
        }
    }


    $(document).on('zg.getProductInfo.success', function(e, data){

        var stockData = data[0];
        //stockData = jQuery.parseJSON(stockData);
        // jQuery('[data-zg-role="option-selector"]').find('a').each(function(){
        //     var dataValue = jQuery(this).attr('data-value');
        //     for(key in stockData.skus){
        //         for(keys in stockData.skus[key].options){
        //             if(stockData.skus[key].options[keys] == dataValue){
        //                 //console.log(stockData.skus[key].options[keys]);
        //                 if(!stockData.skus[key].quantity){
        //                     jQuery(this).addClass('lineThrough');                            
        //                 }

        // var stockData = data[0];
        //stockData = jQuery.parseJSON(stockData);

        jQuery('[data-zg-role="option-selector"]').find('a').each(function(){
            var dataValue = jQuery(this).attr('data-value');

            var disabledBtn= 1;


            for(key in stockData.skus){
                for(keys in stockData.skus[key].options){
                    if(stockData.skus[key].options[keys] == dataValue){
                        //console.log(stockData.skus[key].options[keys]);
                        if(stockData.skus[key].quantity){
                            disabledBtn = 0;
                            //jQuery(this).addClass('lineThrough');
                        }
                    }
                }
            }

            if (disabledBtn == 1){
                jQuery(this).addClass('lineThrough');
            }

        });
        if(jQuery('.size a').length == jQuery('.size .lineThrough').length){
            jQuery('[data-zg-role="availability"]').text('Out of stock');
        }
    });


    /*
    $(document).on('zg.addressForm.ready', function(){
        $("#address_form_modal form.modal-section").validate({
            rules: {
                postcode: {
                    required: true,
                    digits: false
                },
                telephone: {
                    required: true,
                    digits: false
                }
            }
        });
    });
    */

    $(document).on('addressUtils.addressReady', function(e, data){
        for(i=0; i<data.data.length; i++){
            if(data.data[i].is_billing == "1"){
                $('.dyna_user_name').text('Welcome'+' '+data.data[i].first_name);
                $('.u-name').text('Welcome'+' '+data.data[i].first_name);
                $('.addrs_fname').text(data.data[i].first_name);        
            }
        }
    });


    setTimeout(
        function slickSlider_items(){
            $('.related').slick({
                slidesToShow: getSlideItems(6),
                slidesToScroll: 1,
                autoplay: true,
                arrows: true,
                infinite: false,
                dots: false,
                prevArrow: '<a class="slick-prev"><img src="/themes/731/base-template-01/images/base/cycle-prev.png" alt=">"></a>',
                nextArrow: '<a class="slick-next"><img src="/themes/731/base-template-01/images/base/cycle-next.png" alt=">"></a>',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: getSlideItems(4)
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: getSlideItems(3)
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: getSlideItems(2)
                        }
                    }
                ]
            });
        },1000);


    // setTimeout(slickSlider_items(), 1000);

    function getSlideItems(items){
        if ($('.related').find('a[data-zg-role="product"]').length < items) {
            items = $('.related').find('a[data-zg-role="product"]').length
        }
        return items
    }




    $('.product-details .size-options >div:last-child .size .image-option').click(function() {
        $(this).next('input').attr('checked')
        $('.product-info .size .image-option').removeClass('checked')
        $(this).addClass('checked')
    })

    $('.product-details .size-options >div:first-child .size .image-option').click(function() {
        $('.product-details .size .image-option').removeClass('selected')
        $(this).addClass('selected')
    })

    $('.product-details .size-options >div:last-child .size .image-option').click(function() {
        $(this).next('input').attr('active')
        $('.product-info .size .image-option').removeClass('active')
        $(this).addClass('active')
    })

    $('.product-details .size-options >div:first-child .size .image-option').click(function() {
        $('.product-details .size .image-option').removeClass('selected')
        $(this).addClass('selected')
    })


    $('.guest-tab,.login-tab,.register-tab').click(function() { $('.checkout-msg').hide(); });

    $('.menu-large').mouseenter(function() {
        menu = $(this).find('.megamenu')
        $('#filterAffix .dropdown.open').removeClass('open');
        //menu.show()
        timer = setTimeout(function(){
            menu.show()
        },500);
        $(this).mouseleave(function() {
            // if(timer
            if(typeof(timer) !== 'undefinded' ){
                clearTimeout(timer);
            }
            menu.hide()
        })
    })


    $('.dropdown-item a').click(function(e) {
        e.preventDefault()
        window.location = $(this).attr('href')
    })

    // $('.menu-large').click(function(e) {
    //     e.preventDefault()
    //     return false;
    // })

    $('.img-switch').mouseenter(function() {
        $(this).find('.product-img').animate({'opacity': 0})
    }).mouseleave(function() {
        $(this).find('.product-img').animate({'opacity': 1})
    })

    $('.has-submenu a').click(function(e) {

        $('.sidebar-nav').first().animate({'margin-left': '-100%'})
        $($(this).attr('href')).show().animate({'margin-left': 0})
    })

    $('.close-submenu').click(function(e) {
        e.preventDefault()
        $('.sidebar-nav').first().animate({'margin-left': '0'})
        $('.mobile-submenu').animate({'margin-left': '300px'}, function() {
            $(this).fadeOut()
        })
    })

    $('.toggle-filters').click(function(e) {
        e.preventDefault()
        if($(this).hasClass('expanded')) {
            $(this).removeClass('expanded').find('span').html('&plus;')
            $('.plp-filters').slideUp()
        } else {
            $(this).addClass('expanded').find('span').html('&dash;')
            $('.plp-filters').slideDown()
        }
    })


    $('.smoothscroll').click(function(){
        var the_id = $(this).attr("href");
        $('html, body').animate({
            scrollTop:$(the_id).offset().top
        }, 'slow');
        return false;})

    $('[data-toggle="tooltip"]').tooltip()

    $('.options .swatch').click(function() {
        $('.options .swatch').removeClass('selected')
        $(this).addClass('selected').css({'border-color': $(this).find('span').css('background-color')})
    })


    $('.toggle-filter').click(function() {
        if($(this).hasClass('open')) {
            $(this).removeClass('open').find('span').html('&plus;')
        } else {
            $(this).addClass('open').find('span').html('&dash;')
        }
    })

// lookbook
    $('.lookbook-launch').click(function(e) {
        e.preventDefault()
        $('.modal-clone').remove();
        $item = $(this).parents('.lookbook-item').clone();
        $anchor = $(this).find('img').position();

        $item.clone()
            .css({
                position: 'absolute',
                top: $anchor.top ,
                left: $anchor.left,
                background: '#000',
                display: 'block',
                overflow: 'hidden',
                background: $(this).data('bg'),
                width: '100px',
                height: '100px',
            })
            .addClass('modal-clone')
            .appendTo('body')
            .animate({
                top: $('.lookbook-grid').position().top +  'px',
                left: ($('.lookbook-grid').position().left) +  'px',
                width: ($('.lookbook-grid').width() - 24) + 'px',
                height: ($('.lookbook-grid').width()) + 'px'
            })
            .find('img').addClass('valign')
        $item.find('form').fadeIn()
        modalAnchor = $('.lookbook-grid').position()
        $('html, body').animate({
            scrollTop: 200
        }, 1000);

        $('.lookbook-close').click(function(e) {
            e.preventDefault()
            $('.modal-clone').animate({
                left: $(this).position().left + 'px',
                top: $(this).position().top + 'px',
                width: '30px',
                height: '30px',
                opacity: 0
            })
        })
    })

    $('.value-proposition-mobile').not('.slick-initialized').slick({
        arrows: false,
        fade: true,
        autoplay: true,
        speed: 800,
        timeout: 3000,
        sync: false
    });

    if($(window).width() < 768 || ($(window).resize() && ($(window).width() < 768)) ) {

        // $("#gallery").removeClass('col-2').removeClass('col-md-2').width($(window).width());
        // $("#gallery").parent().parent().parent().prepend('<div class="tmp">'+$('.mobile-product-images').html()+'</div>');
        // $('.mobile-product-images').hide();
        // $('.tmp').slick({
        //     dots: true,
        //     arrows: false,
        //     infinite: false,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [

        //         {
        //             breakpoint: 768,
        //             settings: {
        //                 slidesToShow: getSlideItems(3),
        //                 slidesToScroll: getSlideItems(3)
        //             }
        //         }


        //     ]
        // });
        $("#gallery").removeClass('col-2').removeClass('col-md-2').width($(window).width());
        $("#gallery").parent().parent().parent().prepend('<div class="tmp">'+$('.mobile-product-images').html()+'</div>');
        $('.mobile-product-images').hide();
        $('.tmp').slick({
            dots: true,
            speed: 300,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    var slickImage = $(".thumb.slick-slide").length;
    if( slickImage == 1){
        $(".slick-dots").hide();
    }

    $('#feature-tabs .cycle-slideshow').not('.slick-initialized').slick({
        adaptiveHeight: true,
        slidesToShow: 6,
        infinite: false,
        arrows: true,
        prevArrow: '<a class="slick-prev"><img src="/themes/731/base-template-01/images/base/cycle-prev.png" alt=">"></a>',
        nextArrow: '<a class="slick-next"><img src="/themes/731/base-template-01/images/base/cycle-next.png" alt=">"></a>',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }

        ]
    })




    // $(".nav-toggle, .toggled .page-content-wrapper").click(function(e) {
    //     $("#wrapper").toggleClass("toggled");
    //     if( $("#wrapper").hasClass( "toggled" )){
    //         $("body").addClass("toggle-section");

    //     }else{
    //         $("body").removeClass("toggle-section");
    //     }
    // });

    $(window).scroll(function () {
        var offsetTop = $(window).scrollTop();
        //You've scrolled this much:
        var scrollTrigger = 120;
        var scrollBuffer = 80;
        if(offsetTop <= 768) {
            scrollTrigger = 30;
            scrollBuffer = 0;
        }

        if(offsetTop <= 500) {
            scrollTrigger = 0;
        }

        if(offsetTop >= scrollTrigger) {
            $('body').addClass('stuck');
        }

        if(offsetTop <= scrollBuffer){
            $('body').removeClass('stuck');
        }
    });


    $(".logo").show();
    $('.toggle-search').click(function() {
        if($(this).hasClass('visible')) {
            $(this).removeClass('visible')
            $('.mobile-header .search').animate({width: '0%'})
            $(".logo").show();
            $(".productfinder").hide();
        } else {
            $(this).addClass('visible')
            $('.mobile-header .search').animate({width: '100%'})
            $('.mobile-header .search input').focus()
            $(".productfinder").show();
            $(".logo").hide();
            $('.mobile-header .search input').focus()
        }
    })

    $('.sticky-user-action span.u-name').hover(function(){
        $('.sticky-user-drop-list').show();
    });

    var signin_popup = "";

    $('.login_button').hover(function(){
        if(signin_popup!=""){
            clearTimeout(signin_popup);
        }
        $('.login_section').show();
        $("#cart-empty").hide();
        $("#cart-items-nav").hide();

    }, function(e){
        if ($(e.target).is('input')) {
            return;
        }
        if(signin_popup!=""){
            clearTimeout(signin_popup);
        }
        signin_popup = setTimeout(function(){
            $('.login_section').hide();
        },100);
// $('.login_section').hide();
    });

    $('.sticky-login_button').hover(function(){
        if(signin_popup!=""){
            clearTimeout(signin_popup);
        }
        $('.sticky-login_section').show();
        $("#cart-items-nav-sticky1").hide();
        $("#cart-empty-sticky1").hide();
    }, function(e){
        if ($(e.target).is('input')) {
            return;
        }
        if(signin_popup!=""){
            clearTimeout(signin_popup);
        }
        signin_popup = setTimeout(function(){
            $('.sticky-login_section').hide();
        },100);
// $('.login_section').hide();
    });



    $(".basket-summary img").hover(
        function() {
            $(".list-items-container").slideDown(500);
        }, function() {
            $(".list-items-container").slideUp(500);
        });

    $(".nav-tabs a").click(function(){
        $(this).tab('show');
    });


    /***cart***/
    $("#cart-empty").hide();
    $("#cart-empty-sticky").hide();
    $("#cart-empty-mob").hide();
    $("#cart-items-nav").hide();
    $("#cart-items-nav-sticky").hide();
    $("#cart-items-nav-mob").hide();


    $(".cart_bucket").hover(function(){
            $(".login_section").hide();
            var myval=jQuery('#qty-view-range').text();
            if(myval == 0){
                $("#cart-empty").show();
                $("#cart-items-nav").hide();
            }else{
                $("#cart-items-nav").show();
            }
        },
        function(){
            timer = setTimeout(function(){
                $("#cart-empty").hide();
                $("#cart-items-nav").hide();
            }, 300);
        });
    /*Cart simple*/


    $(document).on('zg.getProductListInfo.success' , function(){
        //timer = setTimeout(function(){
        $("#cart-empty").hide();
        $("#cart-items-nav").hide();
        //}, 300);
    });



    $('.user-action').hover(function(){
        $('.user-drop-list').toggle();
        $("#cart-empty").hide();
        $("#cart-items-nav").hide();
    });

    $('.sticky-user-action').hover(function(){
        $('.sticky-user-drop-list').toggle();
        $("#cart-items-nav-sticky1").hide();
    });

    $(".cart-mini").hover(function(){
            $(".login_section").hide();
            var myval1 = $('#qty-view-range_sticky1').text();
            if(myval1 == 0 ){
                $("#cart-items-nav-sticky1").hide();
                $("#cart-empty-sticky1").show();
            }else{
                $("#cart-items-nav-sticky1").show();
            }
        },
        function () {
            timer = setTimeout(function(){
                $("#cart-empty-sticky1").hide();
                $("#cart-items-nav-sticky1").hide();
            }, 300);
        });

    $(document).on('zg.getProductListInfo.success' , function(){

        // timer = setTimeout(function(){
        $("#cart-empty-sticky1").hide();
        $("#cart-items-nav-sticky1").hide();
        $('.cart_bucket_mob').removeClass('actiondisable');
        //}, 300);
    });

    $(".cart_bucket_mob").click(function(){
        var myval= $('#qty-view-range').text();
        if(myval == '0'){
            $("#cart-empty-mob").toggle();
            //$('#cart-items-nav-mob').addClass('d-none');
        }else{
            $("#cart-items-nav-mob").toggle();
            //$('#cart-items-nav-mob').removeClass('d-none');
        }
    });

    if(navigator.userAgent.indexOf('Mac') > 0)
        $('body').addClass('mac-os');

    var isiPad = /ipad/i.test(navigator.userAgent.toLowerCase());
    if (isiPad)
    {
        $('body').addClass('ipad');
    }

    function validate(evt) {
        var theEvent = evt || window.event;
        // Handle paste
        if (theEvent.type === 'paste') {
            key = event.clipboardData.getData('text/plain');
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
});

//re order the sizing to something sensible
//tested on https://nutmeg.morrisons.com/en/brown-raincoat
//variable names based on initial intenions, use may have evolved

$(function () {
    function orderSizes(){
        A = [];

        //define the text parts, and the appropriate order (extend if needed)
        var units = {
            tinyBaby:[],
            newBorn:[],
            mnth:[],
            yrs:[],
            size:[],
            bras:[],
            sml:[]
        };

        //we will put sorting keys in here
        //get an array of all the options

        $(".js-size-list-Size a").each(function () {
            A.push($(this));
        }).promise().done(function () {
            //work on that array:
            var sorting = {};
            //this regex will split into groups the ranges and units
            var r = /(tiny baby)|(new born)|(infant)|([0-9\.]{1,})\-?([0-9\.]{0,})\s([A-za-z]{1,})|(size)\s([0-9]*)|([0-9]{2}[a-z]{1,2})|([smlx]{1,})/;
            //regex grouping index for var e below
            //       (   1    ) (     2     )  (     3     )  (      4     ) (  5 )  (   6  )|(         7        )|(     8    )
            // 0 is the fully matched string, eg "1 - 2 mnth" or "2 - 3 yrs"

            var key;
            var smlSizeMap = {
                s:0,
                m:1,
                l:2,
                xl:3,
                xxl:4
            };

            //loop over the array
            for (i in A) {
                //transform to lower case, trim space etc
                var txt = $.trim($(A[i]).text()).toLowerCase();

                //execute the regex
                var e = r.exec(txt);
                //new born is an exception
                //*
                if (null != e) {
                    switch (true) {
                        case (e[0] == "tiny baby"):
                            units.tinyBaby = [[A[i]]];
                            sorting.tinyBaby = [0];
                            break;
                        case (e[0] == "new born"):
                            units.newBorn = [[A[i]]];
                            sorting.newBorn = [0];
                            break;
                        case (e[0] == "infant"):
                            units.infant = [[A[i]]];
                            sorting.infant = e[4];
                            break;
                        case (e[5] == "size"):
                            //build a key to sort by
                            key = parseInt(e[6]);
                            var unit = "size";
                            break;
                        case (null != e[7]):
                            key = e[7];
                            var unit = 'bras';
                            break;
                        case (null != e[8]):
                            key = smlSizeMap[e[8]];
                            var unit = 'sml';
                            break;
                        default:
                            //use the range for the key - we will add values with leading zeros and concatenate
                            var ra = e[3];
                            var rb = e[4];
                            if (typeof(ra) != 'undefined' && ra.length >= 1) {
                                ra = "0" + (ra * 10);
                            }
                            if (typeof(rb) != 'undefined' && rb.length >= 1) {
                                rb = "0" + (rb * 10);
                            }

                            //and turn the key into an integer
                            key = parseInt(ra + "" + rb);
                            var unit = e[5];

                            if( unit == 'years' ){
                                unit = 'yrs';
                            }
                    }
                    if(e[0] != "new born" && e[0] != "tiny baby" && e[0] != "infant" && key >= 0) {
                        //*/
                        //o is an object, so we can give it dynamic keys, and the array element
                        var o = {};

                        o[key] = A[i];

                        //then start to fill out the units object with the unit and sortable key
                        units[unit][key] = o;

                        if (null == sorting[unit]) {
                            sorting[unit] = [];
                        }

                        //push the sorting key into the sorting array
                        sorting[unit].push(key);
                    }
                }
            }


            //propery sort all the keys we have
            for (unit in sorting) {
                //js sorts alphabetically; this callback sorts numerically
                if(unit == 'bras') {
                    sorting[unit].sort();
                } else {
                    sorting[unit].sort( function ( a, b ) {
                        return a - b;
                    });
                }
            }

            //make the newborn segments behave like the rest
            //

            //define a final order array - we will push into this in the correct order
            var finalOrder = [];

            //first looping over the units
            for (var segment in units) {
                //then use the sorted sorting array to identofy the keys in the units segment
                for (var j in sorting[segment]) {
                    //push into finalOrder
                    finalOrder.push(
                        units[segment][sorting[segment][j]][sorting[segment][j]][0]
                    );
                }
            }

            if(finalOrder.length === $(".js-size-list-Size a").length) {
                //remove the existing links
                $(".js-size-list-Size a").remove();

                //loop over final order and append to parent div
                for (var i in finalOrder) {
                    $(".js-size-list-Size").append(finalOrder[i]);
                }
            }
        });
    }
    orderSizes();
});



//quickview solution



orderSizes = function() {

    $(function () {
        function orderSizes(){
            A = [];

            //define the text parts, and the appropriate order (extend if needed)
            var units = {
                tinyBaby:[],
                newBorn:[],
                mnth:[],
                infant:[],
                yrs:[],
                size:[],
                bras:[],
                sml:[]
            };

            //we will put sorting keys in here
            //get an array of all the options

            $(".js-size-list-Size a").each(function () {
                A.push($(this));
            }).promise().done(function () {
                //work on that array:
                var sorting = {};
                //this regex will split into groups the ranges and units
                var r = /(tiny baby)|(new born)|(infant)|([0-9\.]{1,})\-?([0-9\.]{0,})\s([A-za-z]{1,})|(size)\s([0-9]*)|([0-9]{2}[a-z]{1,2})|([smlx]{1,})/;
                //regex grouping index for var e below
                //       (   1    ) (     2     )  (     3     )  (      4     ) (  5 )  (   6  )|(         7        )|(     8    )
                // 0 is the fully matched string, eg "1 - 2 mnth" or "2 - 3 yrs"

                var key;
                var smlSizeMap = {
                    s:0,
                    m:1,
                    l:2,
                    xl:3,
                    xxl:4
                };

                //loop over the array
                for (i in A) {
                    //transform to lower case, trim space etc
                    var txt = $.trim($(A[i]).text()).toLowerCase();

                    //execute the regex
                    var e = r.exec(txt);
                    //new born is an exception
                    //*
                    if (null != e) {
                        switch (true) {
                            case (e[0] == "tiny baby"):
                                units.tinyBaby = [[A[i]]];
                                sorting.tinyBaby = [0];
                                break;
                            case (e[0] == "new born"):
                                units.newBorn = [[A[i]]];
                                sorting.newBorn = [0];
                                break;
                            case (e[0] == "infant"):
                                units.infant = [[A[i]]];
                                sorting.infant = e[4];
                                break;
                            case (e[5] == "size"):
                                //build a key to sort by
                                key = parseInt(e[6]);
                                var unit = "size";
                                break;
                            case (null != e[7]):
                                key = e[7];
                                var unit = 'bras';
                                break;
                            case (null != e[8]):
                                key = smlSizeMap[e[8]];
                                var unit = 'sml';
                                break;
                            default:
                                //use the range for the key - we will add values with leading zeros and concatenate
                                var ra = e[3];
                                var rb = e[4];
                                if (typeof(ra) != 'undefined' && ra.length >= 1) {
                                    ra = "0" + (ra * 10);
                                }
                                if (typeof(rb) != 'undefined' && rb.length >= 1) {
                                    rb = "0" + (rb * 10);
                                }

                                //and turn the key into an integer
                                key = parseInt(ra + "" + rb);
                                var unit = e[5];

                                if( unit == 'years' ){
                                    unit = 'yrs';
                                }
                        }
                        if(e[0] != "new born" && e[0] != "tiny baby" && e[0] != "infant" && key >= 0) {
                            //*/
                            //o is an object, so we can give it dynamic keys, and the array element
                            var o = {};

                            o[key] = A[i];

                            //then start to fill out the units object with the unit and sortable key
                            units[unit][key] = o;

                            if (null == sorting[unit]) {
                                sorting[unit] = [];
                            }

                            //push the sorting key into the sorting array
                            sorting[unit].push(key);
                        }
                    }
                }


                //propery sort all the keys we have
                for (unit in sorting) {
                    //js sorts alphabetically; this callback sorts numerically
                    if(unit == 'bras') {
                        sorting[unit].sort();
                    } else {
                        sorting[unit].sort( function ( a, b ) {
                            return a - b;
                        });
                    }
                }

                //make the newborn segments behave like the rest
                //

                //define a final order array - we will push into this in the correct order
                var finalOrder = [];

                //first looping over the units
                for (var segment in units) {
                    //then use the sorted sorting array to identofy the keys in the units segment
                    for (var j in sorting[segment]) {
                        //push into finalOrder
                        finalOrder.push(
                            units[segment][sorting[segment][j]][sorting[segment][j]][0]
                        );
                    }
                }

                if(finalOrder.length === $(".js-size-list-Size a").length) {
                    //remove the existing links
                    $(".js-size-list-Size a").remove();

                    //loop over final order and append to parent div
                    for (var i in finalOrder) {
                        $(".js-size-list-Size").append(finalOrder[i]);
                    }
                }
            });
        }
        orderSizes();
    });
}

orderSizes();

// call the orderSizes() function when the product has been rendered in the quickbuy
$(document).on('zg.getProductInfo.productCreated', function(item, options, product){
    if(product && product.type === 'quickbuy'){
        orderSizes();
    }
});
