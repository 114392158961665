/**
 *
 * Loading with ajax, specific info page, in a specific container.
 * Example (in eshop/__footer.html) in this case we loading info page of privacy in a modal popup, on the click for open the popup
 * <a data-toggle="modal" href="#privacy_modal" data-zg-role="load-ajax-file" data-ajax-file-name="xxx.html" data-ajax-container="xxx">
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#click Click on the selector of the plugin
 * @type {null}
 */

(function ( $ ) {
    'use strict';

    /**
     * @selector data-zg-role="load-ajax-file" The plugin start on the click of the selector
     */

    var selector = '[data-zg-role="load-ajax-file"]';
    var root = this;

    var loadAjaxFile = function loadAjaxFile ( fileName, containerSelector, url ) {
        var $container;

        // If fileName overwrite url
        if ( fileName ) {
            url = window.makeUrl( { module: 'eshop', manager: 'info', on: fileName, master: 'master_blank', skip_redir: '1' } );
        }

        if( url && containerSelector ){
            $container = $( '#' + containerSelector );

            if( $container.length > 0 ) {
                $.ajax({
                    url: url,
                    cache: false,
                    dataType: 'html',

                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('X-Requested-With', 'empty');
                        $container.empty().addClass('loading');
                    },

                    success: function (responseText) {
                        $container.empty().append(responseText);
                        $(document).trigger('zg.loadAjaxFile.success', [fileName, containerSelector]);
                    },

                    complete: function () {
                        $container.removeClass('loading hidden');

                    }
                });
            }
        }
        else {
            throw new Error('loadAjaxFile - invalid configuration');
        }
    };

    root.loadAjaxFile = loadAjaxFile;

    /**
     * @method document
     * @listen click On click of SELECTOR start the plugin
     * */
    $( function () {
        $( document ).on( 'click', selector, function () {
            var data = $( this ).data();

            loadAjaxFile( data.ajaxFileName, data.ajaxContainer );
        } );
    } );
}).call( this, jQuery );
