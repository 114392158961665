/**
 * zg-address-actions.js
 *
 * Save an address for EDIT/ADD address form
 * We use this plugin in account page and confirm page for
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 * @author Fabio Polizzi <fpolizzi[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg.addressForm.ready The address form has been rendered. Contains the address information.
 * @type {object}
 * @property {string} action - Action to do with the address. For esample "updateAddress"
 * @property {boolean} actionDelete - True o false if you want delete or not the address
 * @property {boolean} actionEdit - True o false if you want edit or not the address
 * @property {boolean} actionMakeDefault - True o false if you want make default or not the address
 * @property {string} addr_1 - Address line 1
 * @property {string} addr_2 - Address line 2
 * @property {string} addr_3 - Address line 3
 * @property {int} address_id - If of the address
 * @property {string} city -  City
 * @property {string} company_name - Company name
 * @property {array} countriesList - List of countries (for create select of countries)
 * @property {string} country_code - Country code of selected country (ex IT)
 * @property {string} country_code_iso_3 - ISO country code of selected country (ex ITA)
 * @property {string} country_id - Country id (ex for Italy is 106)
 * @property {string} country_name - Country name (always in english)
 * @property {string} create_date - When the address was create (ex 2016-12-13 11:10:52)
 * @property {string} email - Email of the user who created the address
 * @property {boolean} enableInvoiceFields - If the user ask the invoice for this address
 * @property {int} external_id - TO CHECK
 * @property {string} first_name - Name of the user
 * @property {string} fiscal_code - Fiscal code
 * @property {boolean} isCurrentShipping - True o false if the address is default shipping
 * @property {string} is_billing - "0" or "1" if the address is billing address
 * @property {string} is_default - "0" or "1" if the address is default shipping address
 * @property {string} is_deleted - "0" or "1" if the address was deleted
 * @property {int} is_invalid - 0 or 1 if the address is valid for the merchant
 * @property {string} iso_code - ISO code of the country (ex IT)
 * @property {string} label - Label translated of the address, to show like title (ex "Indirizzo di fatturazione")
 * @property {string} last_name - Last name
 * @property {string} mobile - Mobile phone
 * @property {string} p_iva - VAT number (if you want the invoice)
 * @property {string} post_code - Post code
 * @property {string} request_invoice - "0" or "1" if the usere request invoice for this address
 * @property {int} same_address - TO CHECK
 * @property {string} state_code - Code of the address (ex AN)
 * @property {string} state_id - Id of the address
 * @property {string} state_name - State name (ex "Ancona")
 * @property {object} states_list - List of the state of Country id saved (for create select to change it)
 * @property {boolean} store_id - Store id if the address is Store (for pickup in store), otherwise "null"
 * @property {string} telephone - Phone number
 * @property {string} type - type of address. (ex "billing")
 * @property {string} update_date - Date of last update
 * @property {object} user_characteristic - Data of the user of the address
 * @property {string} user_id - User ID
 *
 * @event document#zg-error
 * @type {object}
 * @property {string} eventType - Typology of event error. In this plugin is 'AddressAction - missing action'
 * @property {string} message - The error message to be translated. In this plugin is JS_TRANSLATIONS.genericErrorMsg
 *
 */

/**
 * @event document#click.zg.addressAction Click to address action button
 * @type {null}
 */


/* global _, handlebarsTemplates, JS_TRANSLATIONS, zg_sortElements, zgPost, ZG_CONFIG */

(function ( $, _ ) {
	'use strict';

	var root = this;

	/**
	 * @selector data-zg-role="address-action" SELECTOR of the plugin
	 */
	var SELECTOR = '[data-zg-role="address-action"]';

	/**
	 * @param {string} action Action to be executed on click. Can be case 'delete', 'edit', 'make-default' or 'new'
	 * @param {int} [addressId] Id of the address to edit, delete or make default
	 * @param {string} [editAddressTemplate] Handlebar template for form of the address edit
	 * @param {string} [editAddressContainer] Div container for the template form of the address edit
	 * @param {string} [newAddressTemplate] Handlebar template for form of the address new
	 * @param {string} [newAddressContainer] Div container for the tmplate form of the address new
	 * @param {boolean} [newAddressDefault] Set as true to make new address like default shipping address
	 * @param {string} [newAddressInitialInfo] Initial data for the ADD address form
	 * @param {string} [countriesList] List of countries. This information is only available as a smarty value so it will be set in ZG_CONFIG
	 * @param {boolean} [addressReloadOnSave] Set as true to reload the page when saving an address
	 */
	var DEFAULTS = {
		action: null,
		addressId: null,
		editAddressTemplate:  'address-form',
		editAddressContainer: '[data-zg-role="address-form-container"]',
		newAddressTemplate:   'address-form',
		newAddressContainer:  '[data-zg-role="address-form-container"]',
		newAddressDefault: false,
		newAddressInitialInfo: null,
		countriesList: null,
		addressReloadOnSave: false,
		info: null,
		validateAddressOnShow: false
	};

	/**
	 * Store the parsed countries in a common object so its shared among actions
	 *
	 * @private
	 */
	var __parsedCountriesList;

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var AddressAction = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( DEFAULTS );
		this.__setOptions( options );
	};

	/**
	 * Function called on init the plugin (on click of the SELECTOR), switch the action and call the correct function
	 *
	 * @private
	 */
	AddressAction.prototype.__executeAction = function () {
		switch ( this.options.action ) {
			case 'delete' :
				this.__actionDelete();
				break;

			case 'edit' :
				this.__actionEdit();
				break;

			case 'make-default' :
				this.__actionMakeDefault();
				break;

			case 'new' :
				this.__actionNew();
				break;

			default :
				$( document ).trigger( 'zg-error', [{
					eventType: 'AddressAction - missing action',
					message:   JS_TRANSLATIONS.genericErrorMsg
				}] );

				throw new Error( 'AddressAction - launched with undefined action' );
		}
	};


	/**
	 * remove Address by Id
	 *
	 * @private
	 */
	AddressAction.prototype.__actionDelete = function () {

		if ( this.options.addressId ) {
			zgPost(
				'deleteAddress',
				{ address_id: this.options.addressId },
				{ error: JS_TRANSLATIONS.address_could_not_be_deleted },
				{
					complete: function () {
						root.zgAddressUtils.getAddressList( true );
					}
				}
			);
		} else {
			throw new Error( 'AddressAction.__actionDelete FAILED - please specify an addressId' );
		}
	};


	/**
	 * Edit address by Id. In Guest checkeout, the "id" will be "billing", "shipping", or "both".
     * That is why we are checking the "isNumber" and retrieving the proper information to fill the Edit form
	 *
	 * @private
	 *
	 * @fires document#zg.addressForm.ready  Tell the world when the form to edit an address has been rendered
	 */
	AddressAction.prototype.__actionEdit = function () {
		if ( this.options.addressId ) {
			if ( _.isNumber( this.options.addressId ) ) {
				root.zgAddressUtils.getAddressById(this.options.addressId).then((function (AddressById) {
					AddressById.action = 'updateAddress';
					AddressById.countriesList = this.__parseCountries();
					AddressById.addressReloadOnSave = this.options.addressReloadOnSave;
                    if( AddressById.is_billing ){
                        AddressById.enableInvoiceFields = parseInt( AddressById.is_billing );
                    }
                    AddressById.validateAddressOnShow = this.options.validateAddressOnShow;
					$(this.options.editAddressContainer).html(handlebarsTemplates.render(this.options.editAddressTemplate, AddressById));
					$(document).trigger('zg.addressForm.ready', [AddressById]);
				}).bind(this));
			}
			else{
				var AddressById = _.extend(
					{},
					_.isObject( this.options.newAddressInitialInfo ) ? this.options.newAddressInitialInfo : {}
				);
				AddressById.action = 'updateAddress';
				AddressById.address_id = this.options.addressId;
				AddressById.countriesList = this.__parseCountries();
				AddressById.addressReloadOnSave = this.options.addressReloadOnSave;
                AddressById.enableInvoiceFields = parseInt( AddressById.is_billing );
				AddressById.validateOnShow = this.options.validateAddressOnShow;
				$(this.options.editAddressContainer).html(handlebarsTemplates.render(this.options.editAddressTemplate, AddressById));
				$(document).trigger('zg.addressForm.ready', [AddressById]);
			}
		} else {
			throw new Error( 'AddressAction.__actionEdit FAILED - please specify an addressId' );
		}
	};


	/**
	 * set Address as the default shipping
	 *
	 * @private
	 */
	AddressAction.prototype.__actionMakeDefault = function () {
		if ( this.options.addressId ) {
			zgPost(
				'makeDefaultAddress',
				{ address_id: this.options.addressId },
				null,
				{
					complete: function () {
						root.zgAddressUtils.getAddressList( true );
					}
				}
			);
		} else {
			throw new Error( 'AddressAction.__actionMakeDefault FAILED - please specify an addressId' );
		}
	};


	/**
	 * create new shipping address
	 *
	 * @private
	 *
	 * @fires document#zg.addressForm.ready  Tell the world when the form to create a new address has been rendered
	 */
	AddressAction.prototype.__actionNew = function () {
		root.zgAddressUtils.getAddressList().then( function ( addressList ) {
			var data = _.extend(
				{},
				_.isObject( this.options.newAddressInitialInfo ) ? this.options.newAddressInitialInfo : {}
			);

			data.action              = 'addNewAddress';
			data.address_id          = this.options.addressId;
			data.countriesList       = this.__parseCountries();
			data.addressReloadOnSave = this.options.addressReloadOnSave;
			data.newAddressDefault   = this.options.newAddressDefault;
			data.info   			 = this.options.info;

			if( this.options.isShipping )
				data.isShipping = this.options.isShipping;

			$( this.options.newAddressContainer ).html( handlebarsTemplates.render( this.options.newAddressTemplate, data ) );
			$( document ).trigger( 'zg.addressForm.ready', [data] );
		}.bind( this ) );
	};


	/**
	 *
	 * @private
	 */
	AddressAction.prototype.__parseCountries = function () {
		var list;

		if ( !__parsedCountriesList ) {
			if ( !this.options.countriesList ) {
				throw new Error( 'AddressAction.__parseCountries - missing country list' );
			}

			list = _.map( this.options.countriesList, function ( value, key ) {
				return {
					key:   key,
					value: value
				};
			} );

			__parsedCountriesList = list.sort( zg_sortElements( { attr: 'value' } ) );
		}

		return __parsedCountriesList;
	};


	/**
	 *
	 * @param {Object} options
	 * @private
	 */
	AddressAction.prototype.__setOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );

		if ( this.options.newAddressAutoCreate ) {
			this.__actionNew();
		}
	};


	// ADDRESS ACTION PLUGIN DEFINITION
	// ================================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.addressAction' );
			var options = _.extend( {}, ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.addressAction', ( data = new AddressAction( this, options ) ) );
			}

			data.__executeAction();
		} );
	}

	$.fn.zgAddressAction             = Plugin;
	$.fn.zgAddressAction.Constructor = AddressAction;

	/**
	 * Add listener to on click
	 * @method document
	 * @listen click.zg.addressAction On click of SELECTOR starts the plugin
	 */

	$( function () {
		$( document ).on( 'click.zg.addressAction', SELECTOR, function ( e ) {
			e.preventDefault();
			Plugin.call( $( this ) );
		} );

		if ( $( SELECTOR ).length ) {
			root.zgAddressUtils.getAddressList( true );
		}
	} );

}.call( this, jQuery, _ ));
