/**
 * zg-address-load-states.js
 *
 * - Load states for a country
 * - Hide the state selector for an empty response
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg.addressForm.ready The address form has been rendered. Contains the address information.
 * @type {object}
 * @property {object} Address - Send to the event the address object received by backend See zg-address-actions for details of address
 */


/* global JS_TRANSLATIONS, zgGet, zg_sortElements */

(function ( $ ) {
	'use strict';

	var countrySelector = '[data-zg-role="country-id"]';
	var stateSelector   = '[data-zg-role="state-id"]';

	var statesCache = {};

	var Plugin;
	var initAllCountrySelectors;


	// LOAD STATES CLASS DEFINITION
	// ============================

	/**
	 *
	 * @param {HTMLElement} element
	 *
	 * @constructor
	 */
	var LoadStates = function ( element ) {
		this.$element = $( element );

		this.$form         = this.$element.closest( 'form' );
		this.$statesSelect = this.$form.find( stateSelector );
		this.$container    = this.$statesSelect.closest( '.form-group' );

		// this.currentValue = this.$statesSelect.data( 'value' ) || false;
		this.defaultState = this.$statesSelect.data( 'default' );

		this.__setEventHandlers();
	};


	// LOAD STATES PRIVATE METHODS
	// ===========================


	/**
	 *
	 * @param {string|number} value
	 * @param {string}        text
	 * @param {boolean}       selected
	 *
	 * @private
	 */
	LoadStates.prototype.__createOption = function ( value, text, selected ) {
		return $(
			'<option value="' + value + '"' +
			( selected ? ' selected' : '' ) +
			'>' + text + '</option>' );
	};


	/**
	 *
	 * @private
	 */
	LoadStates.prototype.__fetch = function () {

		var countryId;

		// destroy current options
		this.$statesSelect.empty().val( '' );

		countryId = this.$element.val();

		if ( countryId ) {
			if ( statesCache[countryId] ) {
				this.__renderStates( statesCache[countryId] );
			} else {
				zgGet(
					'loadstates',
					{ cid: countryId },
					null,
					{ success: (this.__parseStates).bind( this ) }
				);
			}
			this.$statesSelect.removeAttr('disabled');
		}else{

			this.$statesSelect.prop('disabled', 'disabled');
		}
	};


	/**
	 *
	 * @param {Object} states - states object returned by the request
	 * @param {Object} data   - request data
	 * @private
	 */
	LoadStates.prototype.__parseStates = function ( states, data ) {

		var sortedStates;

		sortedStates = _.map( states, function ( name, key ) {
			return {
				name:  name,
				value: key
			};
		} );

		sortedStates.sort( zg_sortElements( { attr: 'name' } ) );

		statesCache[data.cid] = sortedStates;

		this.__renderStates( sortedStates );
	};



	/**
	 *
	 * @param {Array} states
	 * @private
	 */
	LoadStates.prototype.__renderStates = function ( states ) {
		var list = [];
		var currentValue;
		_.each( states, function ( item ) {
			if ( item.value ) { // ignore the 'empty' option
				list.push( this.__createOption( item.value, item.name, ( item.value == this.defaultState) ) );
			}
		}, this );

		this.$statesSelect.empty();
        currentValue = $('[data-zg-role="state-id"]').data('value') || false;

		if ( list.length ) {
						

			this.$statesSelect.append( this.__createOption( '', JS_TRANSLATIONS.please_select, false ) );

			// only one state
			if ( list.length === 1 ) {
							
				list[0].prop( 'selected', true );
			}

			this.$statesSelect
				.append( list )
				.prop( 'required', false );

			if(currentValue){
			    $('option[value="' + currentValue + '"]').prop('selected', 'selected');
            }

			this.$container.removeClass( 'hidden' ).fadeIn();

			$(this.$element).trigger('zg.statesRendered');

		} else {
			console.log	(this.$statesSelect);
			// The only response is the "please select" option.
			// Hide the state selector and make it not required.
			// Let backend sort it out.
			this.$statesSelect
				.val( '' )
				.prop({required:false, disabled:true});

			this.$container.hide();

		}
	};


	/**
	 *
	 * @private
	 */
	LoadStates.prototype.__setEventHandlers = function () {
		var that = this;

		this.$element.on( 'change.zg.loadStates', function () {
			that.__fetch();
		} );

		this.$element.on('zg.statesRendered', function(){
			that.$form.validate().element(that.$statesSelect);
		});

	};


	// LOAD STATES PLUGIN DEFINITION
	// =============================

	Plugin = function Plugin () {
		return this.each( function () {
			var $this = $( this );
			var data  = $this.data( 'zg.loadStates' );

			if ( !data ) {
				data = new LoadStates( this );
				$this.data( 'zg.loadStates', data );
			}

			data.__fetch();
		} );
	};

	$.fn.zgLoadStates             = Plugin;
	$.fn.zgLoadStates.Constructor = LoadStates;


	// LOAD STATES DATA-API
	// ====================

	initAllCountrySelectors = function initAllCountrySelectors () {
		$( countrySelector ).each( function () {
			Plugin.call( $( this ) );
		} );
	};

	$( function () {
		/**
		* @method document
		* @listen zg.addressForm.ready On address form ready init the function
		*/
		$( document ).on( 'zg.addressForm.ready', initAllCountrySelectors );

		// start script on page load
		initAllCountrySelectors();
	} );

}( jQuery ));
