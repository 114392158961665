/**
 * Add a product to the wishlist.
 *
 * You can add data-target="#selector" to link the button with
 * a specific product (selector would be the id of an element
 * with data-zg-role="product").
 *
 * <a href="#" data-zg-role="add-to-wishlist" data-target="#product_unique_id">...</a>
 * <div id="product_unique_id" >
 *
 * If data-target is not present the button will just try to find
 * a data-zg-role="product" within its parents.
 *
 * @author
 *
 */

/**
 * @event document#click Click on the selector
 * @type {null}
 */

/**
 * @event document#zg.product.addedToWishlist Product added to wishlist
 * @type {object}
 * @property {int} product_id - Product id of the product added to wishlist
 */



(function ( $ ) { "use strict";

	/**
	 * @selector data-zg-role="add-to-wishlist" The plugin start on click of selector
	 */
	var toggle         = '[data-zg-role="add-to-wishlist"]';
	var toggle_product = '[data-zg-role="product"]';

	/**
	 * Main function
	 *
	 * @param {Element}  element
	 * @param {Object}   config
	 */
	var ZG_addToWishlist = function ( element, config ) {


		var self = this;

		/**
		 * Initialise the module
		 *
		 * @param {Element} element
		 * @param {Object}  config
		 */
		this.init = function ( element, config ) {
			var $element      = $(element);
			self.$element     = $element;
			self.config       = $.extend( {}, config, self.$element.data('config') || {});
			self.$eventBus    = $(document);

			self.$target      = $( $element.data("target") );
			if ( !self.$target || !self.$target.length ) {
				self.$target   = $element.closest( toggle_product );
			}

			if ( !self.$target || !self.$target.length ) {
				// the element doesn't have a target. we hide it and stop the script
				$element.hide();
				return;
			}

			self.product_id   = self.$target.data('id');
			self.inWishlist   = false;

			this.setEventHandlers();

			self.updateButtonInfo();
		};


		/**
		 * @method addToWishlist
		 * @fires document#zg.product.addedToWishlist Product added to wishlist
		 */
		 

		this.addToWishlist = function (element) {
			var $element      = $(element);
			if ( !window.SGL_JS_ISLOGGED ) {
				window.location.href =  window.makeUrl({module: 'eshop', manager: 'wishlist', action: 'addproduct'}) + "pid-wishlist/" + self.product_id;
				return;
			}

			var request = {
				product_id: self.product_id
				//, sku: (self.sku || 0)
			};				
			var url = window.makeUrl({module: 'eshop', action: 'addProductToWishlist'});
            var removeUrl = window.makeUrl({module: 'eshop', action: 'deleteWishlistItem'});

            if($element.closest(".wishlist").hasClass('wishheart')){
                request.items = [request.product_id];
                request.sku= (self.sku || 0);
                request.quantity = '1.00';
                $.ajax( {
                    type:     'post',
                    url:      removeUrl,
                    dataType: 'json',
                    data:     request,
                    success: (function ( request, response ) {
                        $element.closest(".wishlist").removeClass("wishheart");
                        $element.closest(".wishlist").find(".heart").show();
                        $element.closest(".wishlist").find(".heart_saved").hide();

                        self.$eventBus.trigger('zg-notification', [{
                            eventType: 'deleteWishlistItem',
                            message: "Product has been removed from wishlist"
                        }]);

                        self.inWishlist = true;
                        self.updateButton();

                    }).bind( this, request ),

                    error: function ( response ) {
                        self.$eventBus.trigger('zg-error', [{
                            eventType: 'deleteWishlistItem',
                            message: response.message
                        }]);
                    },

                    complete: (function () {
                        //$( document ).trigger( 'zg.getProductList.request-info', ['post', request] );
                        $(document).trigger('zg.product.addedToWishlist', [request]);
                    }).bind( this )
                } );


            }else{
                $.getJSON( url, request, function ( response ) {
                    if ( 'success' === response.status ) {
                         $element.closest(".wishlist").addClass("wishheart");
                        self.$eventBus.trigger('zg-notification', [{
                            eventType: 'added-to-wishlist',
                            message: window.JS_TRANSLATIONS.product_has_been_added_to_the_wishlist || "Product has been added to wishlist"
                        }]);

                        self.inWishlist = true;
                        self.updateButton();
                    } else {
                        self.$eventBus.trigger('zg-error', [{
                            eventType: 'addedToWishlist',
                            message: response.message
                        }]);
                    }

                    $(document).trigger('zg.product.addedToWishlist', [request]);
                });
            }
		};


		/**
		 *
		 */
		this.setEventHandlers = function () {
			self.$eventBus.on("zg.getProductListInfo.success", function ( e, type ) {
				if ( type === 'wishlist' ) {
					self.updateButtonInfo();
				}
			});
		};


		this.updateButtonInfo = function () {
			var list = window.getLastWishlist ? window.getLastWishlist() : null,
				inWishlist = false,
				i;

			if ( list && list.products_in_wishlist ) {
				for ( i = 0; i < list.products_in_wishlist.length && !inWishlist; i++ ) {
					if ( self.targetData && self.targetData.product_id === list.products_in_wishlist[i].product_id ) {
						inWishlist = true;
					}
				}
			}

			self.inWishlist = inWishlist;
			self.updateButton();
		};


		this.updateButton = function () {
			if ( self.inWishlist ) {
				self.$element
						.removeClass( self.config.wishlist_btn_class_default )
						.addClass( self.config.wishlist_btn_class_added );
			} else {
				self.$element
						.removeClass(self.config.wishlist_btn_class_added)
						.addClass(self.config.wishlist_btn_class_default);
			}
		};

		this.init(element, config);
	};

	$.fn.zg_add_to_wishlist = function ( config ) {

		/**
		 * @param {string} [wishlist_btn_class_default] class the button will have by default
		 * @param {string} [wishlist_btn_class_added] class the button will have when its target is in the wishlist
		 */

		var defaults = {
			"wishlist_btn_class_default" : "",
			"wishlist_btn_class_added"   : "added"
		};
		config = $.extend({}, defaults, config || {});

		return this.each( function () {
			var $this = $(this);
			var data = $this.data('zgAddToWishlist');

			if (!data) {
				$this.data('zgAddToWishlist', (data = new ZG_addToWishlist(this, config)) );
			}

			data.addToWishlist($this);
		} );
	};


	/**
	 * @method document
	 * @listen document#click Click on selector of the plugin
	 */

	$(function () {
		$(document).on('click', toggle, function ( e ) {
			e.preventDefault();
			$( this ).zg_add_to_wishlist();
		});
	});


})( jQuery );
