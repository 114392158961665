/* ============================================================
 * Submit the form after changing the field
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ( $ ) {
	'use strict';

	var selector = '[data-zg-role="submit-on-change"]';

	$( document ).on( 'change.zg.submitOnChange', selector, function () {
		var $this = $( this );

		if ( $this.val() ) {

			if( !!$this.data('loadingOverlay') ){
				$this.parent().addClass('loading');
			}

			$this
				.closest('form' )
				.submit();

			// Stop the user from doing stuff while the page reloads.
			// Has to be done after submitting the form or the field won't be sent.
			$this.prop( 'disabled', true );
		}
	} );
}( jQuery ));
