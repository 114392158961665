/**
 * zg-address-save.js
 *
 * Save an address for EDIT/ADD address form
 * We use this plugin in account page and confirm page for
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 * @author Fabio Polizzi <fpolizzi[at]kooomo[dot]com>
 *
 */

/**
 * @event document#submit.zg.addressSave Address saved
 * @type {null}
 */


/* global _, JS_TRANSLATIONS, zgPost */

(function ( $, _ ) {
	'use strict';

	var root = this;

	/**
	 * @selector data-zg-role="address-form" The plugin start when form with selector submit
	 */
	var SELECTOR = '[data-zg-role="address-form"]';

	/**
	 * @param {boolean} [addressReloadOnSave] Set as true to reload the page when saving an address. Set as a URL to redirect to that page on save. - CAUTION! The URL will not be validated!
	 */
	var DEFAULTS = {
		addressReloadOnSave: true
	};


	// ADDRESS SAVE CLASS DEFINITION
	// =============================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var AddressSave = function ( element, options ) {
		this.$element = $( element );

		this.defaultOptions = _.clone( DEFAULTS );
		this.options        = _.extend( this.defaultOptions, options || {} );
	};


	/**
	 *
	 */
	AddressSave.prototype.send = function () {
		var $modal;

		if ( !this.$element.data( 'zgValidator' ) ||
			this.$element.data( 'zgValidator' ).validate() ) {
			this.__post( this.$element.serializeArray() );

			// auto close container modal on submit
			$modal = this.$element.closest( '.modal' );
			if ( $modal.length ) {
				$modal.modal( 'hide' );
			}
		}
	};


	/**
	 *
	 * @param {Array} formData
	 * @private
	 */
	AddressSave.prototype.__post = function ( formData ) {
		var request = {};

		_.each( formData, function ( item ) {
			request[item.name] = item.value;
		} );

		if ( request.action ) {
			zgPost(
				request.action,
				request,
				{
					error:   JS_TRANSLATIONS.address_could_not_be_saved,
					success: JS_TRANSLATIONS.address_have_been_saved
				},
				{
					success: (this.__onComplete).bind( this )
				}
			);
		} else {
			throw new Error( 'AddressSave.__post FAILED - missing action' );
		}
	};


	/**
	 * handle the post-commit action
	 *
	 * @private
	 */
	AddressSave.prototype.__onComplete = function () {		
		if ( this.options.addressReloadOnSave === true ) {
			location.reload();
		} else if ( _.isString( this.options.addressReloadOnSave ) ) {
			location.assign( this.options.addressReloadOnSave );
		} else {
			root.zgAddressUtils.getAddressList( true );
		}
	};


	// ADDRESS SAVE PLUGIN DEFINITION
	// ==============================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.addressSave' );
			var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option ===
				'object' &&
				option );

			if ( !data ) {
				$this.data( 'zg.addressSave', ( data = new AddressSave( this, options ) ) );
			}

			// make sure we always send the ajax request on form submit
			data.send();
		});
	}

	$.fn.zgAddressSave             = Plugin;
	$.fn.zgAddressSave.Constructor = AddressSave;


	/**
	 * @method document
	 * @listen submit.zg.addressSave On submit of SELECTOR start the plugin
	 */
	$( document ).on( 'submit.zg.addressSave', SELECTOR, function ( e ) {
		e.preventDefault();
		Plugin.call( $( this ) );
	} );

}.call( this, jQuery, _ ));
