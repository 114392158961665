	// add your site specific javascript here
    $(window).load(function(){
        $(".load-js").css({visibility:'visible'}).removeClass('load-js');
    });

	$(document).ready(function(){
		if( $('[data-zg-role="autosubmit"]').length ){
			console.log('b');
			$('[data-zg-role="autosubmit"]').submit();
		}
	});


 //PLP filter - removing selected filter options//

	jQuery(document).on('click' , '.clearfilters' , function(){
		jQuery( ".captured a" ).remove();
	});

	jQuery(document).on('click' , '.filter-bottom-button' , function(){
		jQuery( ".captured a" ).remove();
	});

	// START OF SEARCH BAR JS
	
	// function openSearch() {
	// 	document.getElementById("search-bar-full-width").style.display = "block";
	// }


	jQuery('.search-button').on('click', function(e) {
		jQuery('.value-proposition').toggleClass("uspbarhide"); 
		jQuery('#search-bar-full-width').toggleClass("show");
	  });


	  jQuery('.search-button-mobile').on('click', function(e) {
		if ($('#wrapper').hasClass('toggled')  ){
			$('.nav-toggle').click();
		}

		jQuery('.value-proposition').toggleClass("uspbarhide"); 
		jQuery('#search-bar-full-width-mobile').toggleClass("showsearchbar");

	  });


	// END OF SEARCH BAR JS

	jQuery(document).ajaxSuccess(function(obj, data, settings) {
			//	console.log(data.getAllResponseHeaders());
			// $(".cart_sections").show();
		if(settings.url.indexOf('changeUserPassword') != -1){
			var response = jQuery.parseJSON(data.responseText);
	        for(key in response){
	            if(key == 'status' && response[key] == 'success'){
	               location.reload();
	            }
	        }			
		}

	    if(data.getResponseHeader('Content-Type') == 'application/json'){
	        var response = jQuery.parseJSON(data.responseText);
	        for(key in response){
	            if(key == 'total_products'){
	                if(typeof(response[key].total_no_shipcost) !== 'undefined'){
	                    jQuery('.ajaxCartTotal').text(response[key].total_no_shipcost);
	                }
	            }
	        }
	    }
	});

	(function ( $ ) {
		'use strict';

		$.ajaxSetup({
			cache: true
		});

		//For crawler
		if ( !window.JS_ENABLED ) {
			$.ajax({
				url:      window.makeUrl({ module: 'eshop', action: 'setJavascriptEnabled' }),
				type:     'post',
				dataType: 'json'
			});
		}

		// -----------------------------------------------------------------------------------------------------------------
		// Anything to be initialized on document ready goes here

		$( function () {
			//First modal registration
			$( '#registrationModalThanks' ).modal('show');

			// If we have an "active" tab we start with that one selected, otherwise we select the first one
			$( '.nav-tabs' ).each( function () {
				var $activeTab = $( this ).find( '.active [data-toggle="tab"]:first' );
				if ( $activeTab.length ) {
					$activeTab.tab();
				} else {
					$( this ).find( '[data-toggle="tab"]:first' ).tab();
				}
			} );

			// Tooltips
			//$('[data-toggle="tooltip"]').tooltip();

			// Carousel Settings - Home
			$( '.carousel' ).carousel( {} );

	        // SWIPE CAROUSEL
	        var $carousel = $( '.carousel' );
	        if ( $carousel.length ) {
	            $carousel.swiperight( function () {
	                $( this ).carousel( 'prev' );
	            });
	            $carousel.swipeleft( function () {
	                $( this ).carousel( 'next' );
	            });
	        }
		} );

		$( function () {
			$( this ).find( '[data-zg-role="search-input"]').each( function(){
					//Bind the search function
					$( this ).on('input paste', function(){
						// Retrieve the input field text and reset the count to zero
						var filter = $(this).val(), count = 0;

						// Loop through the comment list
						$('[data-zg-role="search-items"]').each(function(){

							// If the list item does not contain the text phrase fade it out
							if ($(this).text().search(new RegExp(filter, "i")) < 0) {
								$(this).fadeOut();

								// Show the list item if the phrase matches and increase the count by 1
							} else {
								$(this).show();
								//if is the first visible I also show the function definition
								/*if(count == 0){
									console.log($(this).find('a'));
								}*/
								count++;
							}
						});

						// Update the count
						if(count > 0){
							$("#noResult").addClass('hide');
						}
						else{
							$("#noResult").removeClass('hide');
						}
						$("#filter-count").text(count);
						$(".count-hide").removeClass('hide');
					});
				}
			);
		});

		//Scroll slowly to the target
		$( function () {
			$('[data-zg-scrollTo="slowly"]').click(function () {
				var target = $(this).data('zg-target'),
					href = $(this).attr("href");
				$('html, body').animate({scrollTop: $(target).offset().top}, 1000);
				window.location.hash = href;
			});
		});

//		$(document).on( 'zg.product.addedToWishlist', function (data) {
//            console.log('dd');
//            setTimeout(function(){
//                var list = window.getLastWishlist ? window.getLastWishlist() : null,
//                    i;
//                if ( list && list.products_in_wishlist ) {
//                    for(i = 0; i < list.products_in_wishlist.length; i++){
//                        jQuery('article[data-id="'+list.products_in_wishlist[i].product_id+'"]').find(".wishlist").addClass('wishheart').addClass('jjj');
//                        jQuery('article[data-id="'+list.products_in_wishlist[i].product_id+'"]').find(".heart").hide();
//                        jQuery('article[data-id="'+list.products_in_wishlist[i].product_id+'"]').find(".heart_saved").show();
//                    };
//                }
//            }, 500);
//	    });

        $( document ).on( 'zg.getProductListInfo.success', (function ( e, type, data ) {
            if ( type === 'wishlist' ) {
                for(var i = 0; i < data.products_in_wishlist.length; i++){
                    jQuery('article[data-id="'+data.products_in_wishlist[i].product_id+'"]').find(".wishlist").addClass('wishheart');
                    jQuery('article[data-id="'+data.products_in_wishlist[i].product_id+'"]').find(".heart").hide();
                    jQuery('article[data-id="'+data.products_in_wishlist[i].product_id+'"]').find(".heart_saved").show();
                }
            }

            if(type === 'cart' && data.total_products.total_items > 0){
				$('[data-zg-role="mobile-cart-count"]')
					.removeClass('hidden')
					.text(data.total_products.total_items);
			}
        }));

		$('#testing ul.tabs li').click(function(){
			var tab_id = $(this).attr('data-tab');
			$('#testing ul.tabs li').removeClass('current');
			$('.tab-content').removeClass('current');
			$(this).addClass('current');
			$("#"+tab_id).addClass('current');
		});	
		
		/*
		$('#sizeGuidePage ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#sizeGuidePage ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#sizeGuidePage.tab-container div.tab').removeClass('active');
			$('#sizeGuidePage.tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#sizeGuidePage ul.tab-nav li:first-child').trigger('click');	
		//*/
		
		$('#accountPage ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#accountPage ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#accountPage.tab-container div.tab').removeClass('active');
			$('#accountPage.tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#accountPage ul.tab-nav li:first-child').trigger('click');

			
		var hashkey = window.location.href;
		hashkey = hashkey.split('/');
		if(hashkey[hashkey.length-1] == 'account'){
			$('#details').trigger('click');	
		}

		if(hashkey[hashkey.length-1] == 'myOrders'){
			$('#orders').trigger('click');	
		}
		
	
		$('#feature-tabs ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#feature-tabs ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#feature-tabs.tab-container div.tab').removeClass('active');
			$('#feature-tabs.tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#feature-tabs ul.tab-nav li:first-child').trigger('click');	

		
	
		$('#first-helpcustomer ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#help-first .tab-container div.tab').removeClass('active');
			$('.tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#first-helpcustomer ul.tab-nav li:first-child').trigger('click');

		
		$('#second-helpcustomer ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#second-helpcustomer ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#help-second .tab-container div.tab').removeClass('active');
			$('#help-second .tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#second-helpcustomer ul.tab-nav li:first-child').trigger('click');
		


		$('#third-helpcustomer ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#third-helpcustomer ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#help-third .tab-container div.tab').removeClass('active');
			$('#help-third .tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#third-helpcustomer ul.tab-nav li:first-child').trigger('click');


	
		$('#fourth-helpcustomer ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#fourth-helpcustomer ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#help-fourth .tab-container div.tab').removeClass('active');
			$('#help-fourth .tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#fourth-helpcustomer ul.tab-nav li:first-child').trigger('click');

	
		$('#fifth-helpcustomer ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#fifth-helpcustomer ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#help-fifth .tab-container div.tab').removeClass('active');
			$('#help-fifth .tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#fifth-helpcustomer ul.tab-nav li:first-child').trigger('click');		


		$('#sixth-helpcustomer ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#sixth-helpcustomer ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#help-sixth .tab-container div.tab').removeClass('active');
			$('#help-sixth .tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#sixth-helpcustomer ul.tab-nav li:first-child').trigger('click');

	
		$('#mobile-size-info select').on('change', function(){    
			var convertValue = this.value;
				if(convertValue == 'inch'){
					$('.convertInch').each(function(){	
						var val = ($(this).text())/2.54;
						$(this).text(Math.round(val));});							

					$('.convertInchDual').each(function(){		
						var valuesizeInch = $(this).text();						
						var valuesInch = valuesizeInch.split('-');						
						var id_part1Inch = valuesInch[0];
						var id_part2Inch = valuesInch[1];
						var id_part11Inch = id_part1Inch/2.54;
						var id_part22Inch = id_part2Inch/2.54;						
						$(this).text(Math.round(id_part11Inch)+'-'+Math.round(id_part22Inch));
					});
				}  
				else if(convertValue == 'cm'){
					$('.convertInch').each(function(){

						// $(this).text($(this).text()*2.54.toFixed(2));
						var inchVal = ($(this).text())*2.54;
						inchVal = inchVal.toFixed(2);
						$(this).text(inchVal);
					});		

					$('.convertInchDual').each(function(){							
						var valuesizeCm = $(this).text();						
						var values = valuesizeCm.split('-');						
						var id_part1 = values[0];
						var id_part2 = values[1];
						var id_part11 = values[0]*2.54;
						var id_part22 = values[1]*2.54;		
						// console.log('values'+id_part11.toFixed(2)+'-'+id_part22.toFixed(2));				
						$(this).text(id_part11.toFixed(2)+'-'+id_part22.toFixed(2));
					});
				} 
		});	

		
		$('#seventh-helpcustomer ul.tab-nav li').click(function(e){
			var tab_id = $(this).attr('id');
			$('#seventh-helpcustomer ul.tab-nav li').removeClass('active');
			$(this).addClass('active');
			$('#help-seventh .tab-container div.tab').removeClass('active');
			$('#help-seventh .tab-container div#' + tab_id + '-tab').addClass('active');
		});
		$('#seventh-helpcustomer ul.tab-nav li:first-child').trigger('click');
		

	 	$(".tabs-menu a").click(function(event) {
	        event.preventDefault();
	        $(this).parent().addClass("current");
	        $(this).parent().siblings().removeClass("current");
	        var tab = $(this).attr("href");
	        $(".tab-content").not(tab).css("display", "none");
	        $(tab).fadeIn();
	    });


		
	
		var change_language = $('select#languages option').length;
		if(change_language = 1){
			$('#change_language').hide();	
		}	

	
		var heading = $("#category_head h1").text();
		$("#dropdownMenuLink").html(heading);
	
		
		/*$(document).ready(function(){
			$(".load-js").css({visibility:'visible'}).removeClass('load-js');
		});*/

		$(document).on('keypress', '.product-details .quantity ', function (e) {
			if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
				$(".quantity").html("Please Enter Digits Only").show();
				return false;
			}
		});

        if ($(window).width() < 992) {
            $( ".main-menu-bar a" ).click(function( event ) {
                event.preventDefault();
                $(this).unbind("click");
            });
        }
        if ($(window).width() <= 480) {
            $('#wishlist-container').attr('data-page-items', 2);
        }
        if($(window).width() > 480 && $(window).width() <= 767 ){
            $('#wishlist-container').attr('data-page-items', 4);
        }

        $(".content-filter a").hover(function(event) {
            event.preventDefault();

        });

		if ($(window).width() < 768) {		
			$(".main-nav .search.productfinder").remove();
		}

		if ($(window).width() > 768) {		
			$(".mobile-header .search.productfinder").remove();
		}
		
        $('.sort-by-list li').click(function(){
            var textSort = $(this).find('a').text();
            $('.pull-left button').text(textSort);
        });

        if ($(window).width() < 768) {
	        $('.sort-by-list li').click(function(){
	            var textSorts = $(this).find('a').text();
	            $('.sorted-text button').text(textSorts);
	        });
        }


	$(document).ready(function(){
		function setCookie(cname, cvalue, exdays) {
			var d = new Date();
			d.setTime(d.getTime() + (exdays*24*60*60*1000));
			var expires = "expires="+ d.toUTCString();
			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		}

		function getCookie(cname) {
			var name = cname + "=",
				decodedCookie = '',
				ca;
			try {
				decodedCookie = decodeURIComponent(document.cookie);
			} catch(err){
				console.log(err);
			}
			var ca = decodedCookie.split(';');
			for(var i = 0; i <ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		}

		if($("img.lazy").length){
			$("img.lazy").unveil(1,function(){
				$(this).load(function(){
					$(this).animate({'opacity':1},700);
				});
			});
		};

		$( ".findStore" ).click(function() {
			$( "#filter_ZipCode .form-control" ).trigger( "change" );
		});

				
		var test = $('#filter_ZipCode .inputCode').val();
		$('#collectionPointsModal .inputCode').val(test);


		//set cookies
		$(document).on('click','#saveContinue',function(){
			setCookie('saveForm', 1, 1);
			return true;
		});

		$(document).on('change','#shipping-address-diffrent #address_selector',function(){
			setCookie('saveForm', 1, 1);
			return true;
		});
		
		if(getCookie('saveForm') === '1'){
			if($("#addresses").length != 0){
				$('#addresses').trigger('click');
			}

		}
		if(getCookie('saveForm') === '1') {
			$('#homeDeliveryLabel').trigger("click").trigger("change");
			$('#deliveryOptionsLabel').prop('checked', true);
			$('.subclickCollect').addClass("intro");
			$('.confirm-container').addClass("hidden");
			if($('.subHomeDelivery').hasClass('intronew')){
				$('.subHomeDelivery').removeClass('intronew')
			}
			$(".headingConfirm .delivery-title").show();
			$(".headingConfirm .collect-title").hide();
			setCookie('saveForm', 0, 1);
		}
	});		


	// Update email user from account website section
	$('[data-zg-role="update-email-account"]').each(function(){
		$(this).on('click',function(){
			var data={email:$('[data-zg-role="email-to-update"]').val()};		
			var callback = {
				success: _.bind( function ( data ) {
					console.log(data);
				}, this, data ),

				error: function (err) {
					console.log(err);
				}
			};
			zgPost( 'updateUser', data, null, callback );
		});
	});

        $('[data-zg-role="list-item-remove"] .btn-yes').each(function(){
            $(this).on('click',function(){
               console.log('removed');
               $('[data-zg-role="list-item-remove"] .btn-close').attr("disabled", true);
               $('[data-zg-role="list-item-remove"] .btn-yes').html('<i class="fa fa-circle-o-notch fa-spin"></i> &nbsp;');
            });
        });

	// anchors on terms and condition pages
	$('.page-section-links a').click(function(e){
		e.preventDefault();
		var anchor = $(this).attr('href');
		$('html,body').animate({scrollTop: $(anchor).offset().top -120}, 300);
	});

	$("#site_main").click(function(){			
		if(	$("#wrapper").hasClass( "toggled" )){
			$("#wrapper").removeClass( "toggled" )
		}
	});
        $('#selectedAddressId').on('change', function() {
            $("#registershipping_address_post_code-error").css("display", "none");
        });
        $(".nav-toggle, .toggled .page-content-wrapper").click(function(e) {
			 $("#wrapper").toggleClass("toggled");
			 
			 if ( $(".mobile-menu-text").text() == "close" ){
				$(".mobile-menu-text").text("menu");
			 }
			 else{
				$(".mobile-menu-text").text("close"); 
				$('.bottom-burger-menu').css('background-image', "url('../../images/base/closemenu.png')");
			 }

			 if ( $(".value-proposition").hasClass("uspbarhide") ){
                jQuery('.value-proposition').toggleClass("uspbarhide"); 
                jQuery('#search-bar-full-width-mobile').toggleClass("showsearchbar");
    		}
			


        });
        $(document).on("click", function(event){
            if( $("#wrapper").hasClass( "toggled" )){
                $("body").addClass("toggle-section");

            }else{
                $("body").removeClass("toggle-section");
            }
        });
        $(document).mouseup(function(e)
        {
            var container = $("#cart-items-nav-mob");

            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(e.target) && container.has(e.target).length === 0)
            {
                container.hide();
            }
        });
        $(document).mouseup(function(e)
        {
            var container = $("#cart-empty-mob");
            if (!container.is(e.target) && container.has(e.target).length === 0)
            {
                container.hide();
            }
        });
        $(document).mouseup(function(e)
        {
            var container = $("#cart-items-nav");
            if (!container.is(e.target) && container.has(e.target).length === 0)
            {
                container.hide();
            }
        });
        $(document).mouseup(function(e)
        {
            var container = $("#cart-items-nav-sticky1");
            if (!container.is(e.target) && container.has(e.target).length === 0)
            {
                container.hide();
            }
        });
        $(document).on("click", function(event){
             var bucketlength = $(".cart_bucket_mob").length;
             if(($('#cart-empty-mob').css('display') == 'none') && (bucketlength == 1))
             {
                 $( "body" ).removeClass( "cart-block" );
             }else if(($('#cart-empty-mob').css('display') == 'block') && (bucketlength == 1)){
                 $( "body" ).addClass( "cart-block" );
             }
		});





        $(document).on("click", function(event){
             var bucketlength = $(".cart_bucket_mob").length;
             if(($('#cart-items-nav-mob').css('display') == 'none') && (bucketlength == 1))
             {
                 $( "body" ).removeClass( "cart-items" );
             }else if(($('#cart-items-nav-mob').css('display') == 'block') && (bucketlength == 1)){
                 $( "body" ).addClass( "cart-items" );
             }
        });

		$('button.toggle-refund').click(function(){
			var $select = $(this).siblings('select');

			if(this.classList.contains('selected')){
				$select.val('').trigger('change.zg.return');
			} else {
				$select.val('refund').trigger('change.zg.return');;
			}

			this.classList.toggle('selected');
		});

		/* Sort Orders by order date on the My Orders page */
		var sortOrderTable = document.querySelector('[data-zg-role="sortByDate"]');
		sortTableByDate(sortOrderTable);
		function sortTableByDate(table){
			var rows, switching, i, x, y, shouldSwitch;
			if(!table || table.nodeName !== 'TABLE') return false;

			switching = true;
			while (switching) {
				switching = false;
				rows = table.rows;
				for (i = 1; i < (rows.length - 1); i++) {
					shouldSwitch = false;
					x = rows[i].dataset.orderDate;
					y = rows[i + 1].dataset.orderDate;
					if (x < y) {
						shouldSwitch = true;
						break;
					}
				}
				if (shouldSwitch) {
					rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
					switching = true;
				}
			}
		}

 		// trigger validation onload
		$(document).ready(function(){
			
			

			var validator = $('#addressForm').validate();
			if (!validator) {
				return;
			}
			validator.form();
		});
		

		//empty the captured div on page load

		function setFilteredOptions() {
			//wait for kooomo default funtionality
			setTimeout(function(){
				var $captured = $( '.captured' );
				//empty captured div
				$captured.empty();
				//fill with all selected filters
				$("[data-zg-role='filters'] select").each(function( i, select ){
					//$(".captured").append($(this).clone());
					//console.log("select", $(select).find( 'option:selected').val().trim() );
					var $select = $( select );
					var $optionSelected = $select.find( 'option:selected');
					if( $optionSelected.length ) {
						var selectedValues = $optionSelected.val().trim();
						var selectedTxt = $optionSelected.text().trim();
					}
					var selectedFilterId = $select.data( 'filter' );
					if( selectedValues ) {
						console.log("selected selectedFilterId", selectedFilterId);
						$captured.append( '<div class="captured-tags txt-filter active filtered" data-filter-id="' + selectedFilterId + '" data-filter-value="' + selectedValues + '" data-zg-role="filtered-tag">' + selectedTxt + '</div>' );
					}
				});
			},125);
		}

		setFilteredOptions();


		//on click of filter
		$(document).on("change", '[data-zg-action="filter"]' , function(e){

			//prevent default event action (method 1)
			e.preventDefault();
		
			setFilteredOptions();

			//prevent default event action (method 2)
			return false;
		});

		//on click of captured filter
		$(document).on("click", ".captured a.txt-filter" , function(e){
			//prevent default event action (method 1)
			e.preventDefault();

			//grab a unique value from the filter option
			var dv = $(this).attr('data-value');

			//use this value to find the actual filter and execute its click method - so the aove block of code runs
			$("#filter-container .txt-filter[data-value='"+dv+"']").trigger('click');

			//prevent default event action (method 2)
			return false;
		});
		
		/// filter code 

		$('[data-zg-role="disablePageAfterClick"]').click(function(){
			$(this).closest('section').addClass('loading');
		});


		// show a warning when add to cart is click but options not selected
		$('[data-zg-role="handle-disabled-click"]').one('click', function(){
			$(this)
				.closest('.row')
				.find('.options-warning')
				.removeClass('hidden');
		});

		$(document).on('zg.getProductInfo.productCreated', function(item, options, product){
			if(product && product.type === 'quickbuy'){
				$(product.productsContainer).find('[data-zg-role="handle-disabled-click"]').one('click', function(){
					$(this)
						.closest('.row')
						.find('.options-warning')
						.removeClass('hidden');
				});
			}
		});

		$(document).on('zg.product.optionSelected', function(){
			$('.options-warning').addClass('hidden');
		});

		$('#filterAffix').affix({
			offset: {
				top: 250,
				bottom: function () {
					return (this.bottom = $('#site_footer').outerHeight(true))
				}
			}
		})

    })( jQuery );