/**
 * zg-cart.js
 *
 * Display an manage ajax cart and ajax wishlist in header
 *
 * @author David Pocina  <dpocina[at]kooomo[dot]com>
 *
 */

/**
 * @event document#zg.getProductListInfo.success The ajax request for cart or wishlist products has been done. Contain type (cart or wishlist, list of products and option for display.
 * @type {object}
 * @property {string} type - If is cart or wishlist
 * @property {object} response - The list of the products
 * @property {boolean} showCart - If open the dropdown or not
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event error.
 * @property {string} message - The error message to be translated.
 */

/**
 * @event document#zg.getProductList.request-info  Request info for cart or wishlist, contains the typology of info
 * @type {string}
 */

/**
 * @event document#zg.product.addedToCart Product added to cart
 * @type {null}
 */

/**
 * @event document#zg.product.addedToWishlist Product added to wishlist
 * @type {null}
 */

/**
 * @event document#zg.getProductList.updated Product list updated and rendered
 * @type {null}
 */





(function ( $ ) { /* global DEBUG */
    'use strict';

    // VARIABLES
    // =========

    // Establish the root object ('window' in the browser)
    var root = this;

    var url  = {
            cart:     window.makeUrl( { module: 'eshop', action: 'getCartInformation' } ),
            wishlist: window.makeUrl( { module: 'eshop', action: 'getWishlistInformation' } )
        },

        last = {
            cart:     null,
            wishlist: null
        };



    /**
     * Make ajax call for get cart or wishlist and change total and quantity in header
     * @method getProductListInfo
     *
     * @fires document#zg.getProductListInfo.success  Tell the world when the ajax with list of products has success
     *
     */
    function getProductListInfo ( type, showCart ) {
        var requestData = {};

        type = type || 'cart';

        if(type === 'wishlist'){
            requestData.check_valid = true;
        }

        $.ajax( {
            type:     'post',
            url:      url[type],
            dataType: 'json',
            data: requestData,

            success: function ( response ) {
                if ( DEBUG ) {
                    console.log( 'getProductListInfo', type, response );
                }

                if ( response && response.status === 'success' ) {
                    last[type] = response;

                    if ( response.total_products.total_amount ) {
                        $( '[data-zg-role="' + type + '-total"]' ).text( response.total_products.total_amount );
                    }

                    if ( response.total_products.total_no_shipcost ) {
                        $( '[data-zg-role="' + type + '-total-noshipcost"]' ).text( response.total_products.total_no_shipcost );
                    }

                    if ( response.total_products.total_items >= 0 ) {
                        $( '[data-zg-role="' + type + '-items-count"]' ).text( response.total_products.total_items );
                    }

                    $( document ).trigger( 'zg.getProductListInfo.success', [type, response, showCart] );
                }
            }
        } );
    }

    /*
     *
     */
    function getLastList ( type ) {
        type = type || 'cart';

        return last[type];
    }

    root.getLastList = getLastList;

    root.getLastCart = function () {
        return getLastList( 'cart' );
    };

    root.getLastWishlist = function () {
        return getLastList( 'wishlist' );
    };


    // EVENT HANDLERS
    // ==============

    /**
     * @method document
     * @listen document#zg.getProductList.request-info On request info (for example after clear product by cart) start function for request info and render cart
     */
    $( document ).on( 'zg.getProductList.request-info', function ( e, type ) {
        getProductListInfo( type );
    } );

    /**
     * @method document
     * @listen document#zg.product.addedToCart Update the cart when a product is added to it. The dropdown list is displayed
     */
    $( document ).on( 'zg.product.addedToCart', function () {
        getProductListInfo( 'cart', true );
    } );

    /**
     * @method document
     * @listen document#zg.product.addedToWishlist Update the wishlist when a product is added to it. The dropdown list is displayed
     */
    $( document ).on( 'zg.product.addedToWishlist', function () {
        getProductListInfo( 'wishlist', true );
    } );

    // request cart and wishlist info on page load
    $( function () {
        getProductListInfo( 'cart' );
        getProductListInfo( 'wishlist' );
    } );
}.call( this, jQuery ));


/* ============================================================
 * zg-Cart.js
 *
 *
 * ============================================================ */

(function ( $, _ ) { /* global _, handlebarsTemplates, JS_TRANSLATIONS */
    'use strict';


    // GETPRODUCTLIST CLASS DEFINITION
    // ===============================

    var GetList = function ( element, type, options ) {
        this.$element = $( element );
        this.options  = options;
        this.type     = type;

        this.$container = $( this.options.elementContainer, this.$element );

        this.setEventHandlers();
        this.init();
    };


    /**
     * @param {boolean} [autoShow] Open the dropdown list when it is updated.
     * @param {boolean} [hideIfEmpty] Hide the list when it is empty.
     * @param {string} [elementContainer] Div contain the ajax cart template
     * @param {string} [elementItemRemove] Button for delete product from ajax cart
     * @param {string} [template] Handlebars template of ajax cart
     */
    GetList.DEFAULTS = {
        autoShow: true,
        hideIfEmpty: false,
        elementContainer:  '[data-zg-role="list-items-container"]',
        elementItemRemove: '[data-zg-role="list-item-remove"]',
        template: 'products-list-header'
        // urlEmptyList: window.makeUrl({module: 'eshop', action: 'emptyCart'})
    };

    /**
     *
     */
    GetList.prototype.init = function () {
        var data = window.getLastList( this.type );
        if ( data ) {
            this.updateList( data );
        }
    };


    /**
     * Remove a product from the list
     *
     */
    GetList.prototype.removeItem = function ( productData ) {
        var complexId = ( productData.id + (productData.sku ? '.' + productData.sku : ''));
        var request   = {
            items:      [complexId],
            product_id: productData.id,
            sku:        productData.sku,
            quantity:   productData.quantity || 1,
            name:       productData.name || '',
            price:      Number( productData.price ) || 0
        };

        if ( productData.custom != '' ) {
            request.custom_id = productData.custom;
        }

        var urlItemRemove = {
            cart:     window.makeUrl({ module: 'eshop', action: 'delProductFromCart' }),
            wishlist: window.makeUrl({ module: 'eshop', action: 'deleteWishlistItem' })
        };

        $.ajax( {
            type:     'post',
            url:      urlItemRemove[this.type],
            dataType: 'json',
            data:     request,

            beforeSend: (function (xhr) {
                $('.cart_bucket_mob').addClass('actiondisable');
                this.$container.addClass( 'loading' );
                xhr.setRequestHeader('X-CSRF-TOKEN', window.CSRFToken);
            }).bind( this ),

            success: (function ( request, response ) {
                if ( response && response.status !== 'success' ) {
                    $( document ).trigger( 'zg-error', [{
                        message: (response.message || JS_TRANSLATIONS.genericErrorMsg)
                    }] );
                }
                $( document ).trigger( 'zg.product.removedFromCart', [this.type, response, request] );
            }).bind( this, request ),

            error: function ( response ) {
                $( document ).trigger( 'zg-error', [{
                    message: (response.message || JS_TRANSLATIONS.genericErrorMsg)
                }] );
            },

            complete: (function () {
                /**
                 * @method GetList.prototype.removeItem
                 * @fires document#zg.getProductList.request-info Ajax for remove product from cart or wishlist is completed
                 *
                 */
                $( document ).trigger( 'zg.getProductList.request-info', [this.type, request] );
            }).bind( this )
        } );
    };


    /**
     *
     *
     */
    GetList.prototype.setEventHandlers = function () {
        /**
         * Add listener to on zg.getProductListInfo.success
         * @method getProductListInfo
         *
         * @listen zg.getProductListInfo.success On this event, update the ajax list of the products on header
         *
         */
        $( document ).on( 'zg.getProductListInfo.success', (function ( e, type, data, showCart ) {
            if ( this.type === type ) {
                this.updateList( data, showCart );
            }
        }).bind( this ) );

        //
        this.$container.on( 'click.zg.GetList', this.options.elementItemRemove, (function ( e ) {
            var $elem = $( e.currentTarget );

            e.preventDefault();

            this.removeItem( $elem.data() );
        }).bind( this ) );

        /**
         * Add listener to zg.product.removedFromCart
         * When on cart page
         * - if item removed from cart using header cart - remove the item from the cart table
         */
        $( document ).on('zg.product.removedFromCart', function handleProductRemove(e, type, response, data){
            if( response.status !== 'success' ){
                return;
            }

            // return if not on the cart page
            if( $('.cart-product [data-zg-role="cart"]').length === 0 ){
                return;
            }

            $('[data-zg-role="cart"] [data-selected-sku="' + data.sku + '"]')
                .remove();

            if( $('[data-zg-role="cart"] [data-zg-role="product"]').length === 0 ){
                location.reload();
            }
        });
    };


    /**
     * Render the products for the list
     * Use the Pagination Plugin if available
     */
    GetList.prototype.renderList = function ( data ) {
        var itemsData;

        if ( this.$container.is( '[data-zg-role="pagination"]' ) ) {
            //When a product is removed from the Wishlist and it was the last one in the list,
            //the "Send Wishlist to a friend" button will be hide
            if( this.type == 'wishlist' && !data["products_in_wishlist"].length ){
                $( '[data-zg-role="sendWishlistBtn"]' ).hide();
                $( '[data-zg-role="continueShoppingLink"]').removeClass('hidden');
            }

            this.$container.zg_pagination( null, data['products_in_' + this.type] || [] );
        } else {
            itemsData = {
                type:           this.type,
                products:       data['products_in_' + this.type] || [],
                total_products: data.total_products || {}
            };

            this.$container
                .addClass( 'loading' )
                .empty()
                .html( handlebarsTemplates.render( this.options.template, itemsData ) )
                .removeClass( 'loading' );
        }
    };


    /**
     * Show the DropDown list
     * Automatically close it later
     */
    GetList.prototype.showCart = function () {
        if ( !this.$element.is( '.open' ) ) {
            $( '[data-toggle="dropdown"]:visible', this.$element ).dropdown( 'toggle' );
        }
    };


    /**
     *
     *
     * @param {Object} data
     * @param {?boolean=} showCart
     */

    /**
     * @method updateList
     * @fires document#zg.getProductList.updated Product list are updated
     *
     */
    GetList.prototype.updateList = function ( data, showCart ) {
        // render the products for the list
        this.renderList( data );


        // $total.text( data.total_products.total_amount );
        // $items.text( data.total_products.total_items );

        if ( !data.total_products || !data.total_products.total_items ) {
            // If there are no products and the configuration says so we hide the list
            if ( this.options.hideIfEmpty ) {
                this.$element.addClass( 'hidden' );
            }
        } else {
            // There are products, we display again the list
            this.$element.removeClass( 'hidden' );

            // Open the list
            if ( showCart && this.options.autoShow ) {
                this.showCart();
            }
        }

        $( document ).trigger( 'zg.getProductList.updated' );
    };


    // GETPRODUCTLIST PLUGIN DEFINITION
    // ================================

    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this ),
                data    = $this.data( 'zg.getProductList' ),
                options = $.extend( {}, GetList.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option ),
                type    = (typeof option === 'string' && option) || options.type || 'cart';

            if ( !data ) {
                $this.data( 'zg.getProductList', (data = new GetList( this, type, options )) );
            }

        } );
    }

    $.fn.getProductList             = Plugin;
    $.fn.getProductList.Constructor = GetList;

    /**
     * @selector data-zg-role="cart" The plugin start if there is the selector in the dom when the page load
     * @selector data-zg-role="wishlist" The plugin start if there is the selector in the dom when the page load
     */


        // GETPRODUCTLIST DATA-API
        // =======================

    $( function () {
        $( '[data-zg-role="cart"]' ).each( function () {
            Plugin.call( $( this ), 'cart' );
        } );

        $( '[data-zg-role="wishlist"]' ).each( function () {
            Plugin.call( $( this ), 'wishlist' );
        } );
    } );

}( jQuery, _ ));
