/**
 *
 * Manage autocomplete search
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * DISCLAIMER:
 *   I just re-wrote this.
 *   I'm not sure about what half of the input fields are
 *   supposed to do or why are we spliting the product name
 *   in " - "
 *
 */

(function ( $ ) { "use strict";

    /**
     * @selector data-zg-role="product-finder-elastic" The plugin start if there is the selector is in the dom when the page load
     */

    var toggle                  = '[data-zg-role="product-finder-elastic"]';
    var toggle_form             = '[data-zg-role="product-finder-elastic-form"]';
    var toggle_search_input     = '[data-zg-role="search-elastic-input"]';
    var toggle_search_type      = '[data-zg-role="search-type"]';
    var toggle_search_id        = '[data-zg-role="search-id"]';
    var toggle_product_id       = '[data-zg-role="search-product_id"]';
    var toggle_product_name     = '[data-zg-role="search-product_name"]';
    var toggle_typeahead_ul     = '[data-zg-role="typeahead-ul-elastic"]';
    var toggle_opacity_search   = '[data-zg-role="opacity-search"]';
    var toggle_btn_search     = '[data-zg-role="btn-elastic-search"]';
    var toggle_search_container = '#searchElasticContainer';
    var toggle_erase_btn        = '#typeahead-erase-button';

    var ZG_Search = function ( element, config ) {
        var self = this;

        this.init = function ( element, config ) {
            var $element       = $(element);
            self.$element      = $element;
            self.config        = $.extend( {}, config, self.$element.data('config') || {} ) ;
            self.$eventBus     = $(document);

            self.$toggle_form           	= $element.find( toggle_form );
            self.$search_input        		= $element.find( toggle_search_input );
            self.$typeahead_ul        		= $element.find( toggle_typeahead_ul );
            self.$toggle_erase_btn 			= $element.find( toggle_erase_btn );
            self.$toggle_btn_search       	= $element.find( toggle_btn_search );
            self.$toggle_search_container 	= $element.find( toggle_search_container );
            self.$toggle_opacity_search   	= $element.find( toggle_opacity_search );

            self.$toggle_form.on("submit", self.searchProducts);
            self.__setEventHandlers();
            self.startTypeAhead();
        };

        this.selectElasticSearch = function () {
            var val = this.$menu.find('.active').attr('data-value');
            var val2 = val.replace(/<em>/gi, '');
            var valFinal = val2.replace(/<\/em>/gi, '');
            if (typeof val !== 'undefined') {
                this.$element
                    .val(this.updater(valFinal))
                    .change();

                if (this.ajax && this.ajax.loadProductPageBy != null){
                    var id = this.$menu.find('.active').attr('data-id');
                    var type = this.$menu.find('.active').attr('data-type');
                    if( id > 0 ){
                        this.$element.attr('data-id',id);
                        this.$element.attr('data-type',type);

                        if(type == 'product'){
                            this.$element.attr('data-value',this.$menu.find('.active').attr('data-value'));
                        }
                    } else {
                        this.$element.val(valFinal);
                    }
                    this.$element.closest( toggle_form ).submit();
                }
            }
            return this.hide();
        };

        this.showElasticSearch = function() {
            this.$menu
                .insertAfter(this.$toggle_form)
                .show();
            let str1 = "" + $("#suggestions-container")[0].offsetHeight;
            let str2 = "px";
            let res = str1.concat(str2);
            if ($("#suggestions-container")[0].offsetHeight > 0) {
                this.$menu[0].style.minHeight = res;
            } else {
                this.$menu[0].style.minHeight = "100px";
            }
            this.shown = true;
            return this;
        };

        this.hideElasticSearch = function () {
            this.$menu.hide();
            this.shown = false;
            self.$toggle_opacity_search.removeClass('opacity-search');
            return this;
        };

        this.renderElasticSearch = function ( items ) {
            var that = this;

            items = $(items).map(function (i, item) {
                let resultName = item.product_data.name;
                let resultDesc = item.product_data.short_description;
                let resultImg = item.product_data.images.small;
                let resultPrice = item.product_data.price.sell;
                let resultCurrency = item.product_data.price.currency_symbol;
                let resultId = item.product_id;
                let resultType = item.type;
                let resultUrl = makeUrl( { module: 'eshop', manager: 'product', pid: resultId } );
                let suggestions = items[0].suggestions;
                let highlights = item.highlight;
                if ( that.ajax && that.ajax.displayField != null && that.ajax.loadProductPageBy != null ) {
                    i = $(that.options.item).attr('data-value', item.product_data.name);
                    i.find("span[data-elastic='name']").html(resultName);
                    i.find("div[data-elastic='desc']").html(resultDesc);
                    if (highlights != null && Array.isArray(highlights)) {
                        highlights.forEach((param)=>{
                            if (param.field == '>names<'){
                                i.find("span[data-elastic='name']").html(param.values);
                            }
                            if (param.field == '>short_description<') {
                                i.find("div[data-elastic='desc']").html(param.values);
                            }
                        });
                    }
                    i.attr('data-id', resultId);
                    i.attr('data-type', resultType);
                    i.find("a").attr('href', resultUrl);
                    i.find("img").attr('src', resultImg);
                    i.find("img").attr('alt', resultName);
                    i.find("span[data-elastic='price']").html(resultPrice);
                    i.find("span[data-elastic='currency']").html(resultCurrency);
                    i.find("ul[data-elastic='suggestions']").attr('id', 'suggestions-container');
                    if (suggestions == null || suggestions.length == 0) {
                        i.find("a").attr('style', 'width:100%;');
                        i.find("ul[data-elastic='suggestions']").attr('style', 'display:none;');
                    } else if (suggestions.length != 0) {
                        let suggestionsArr = [];
                        for (let sug in this.suggestions){
                            suggestionsArr.push(this.suggestions[sug]);
                            if (suggestionsArr.length > 10){
                                break;
                            }
                            i.find("ul[data-elastic='suggestions']").append("<li data-elastic='suggestion'></li>");
                        }
                        for (let index = 0; index < suggestionsArr.length; index++){
                            i.find("li[data-elastic='suggestion']:nth-child("+(index+1)+")").html(suggestionsArr[index]);
                            i.find("li[data-elastic='suggestion']:nth-child("+(index+1)+")").attr('data-value', suggestionsArr[index]);
                            i.find("li[data-elastic='suggestion']:nth-child("+(index+1)+")").attr('data-id', 0);
                            i.find("li[data-elastic='suggestion']:nth-child("+(index+1)+")").attr('data-type', 'suggestion');
                        }
                    }

                } else {
                    i = $(that.options.item).attr('data-value', item)
                    i.find('a').html(that.highlighter(item))
                }
                return i[0]
            });

            //items.first().addClass('active');
            this.$menu.html(items);
            this.$menu.removeClass( 'loading show' );

            return this;
        };

        this.keyboardNavigation = function ( key, $menu ){
            var selectedElem = false;
            var $elasticSuggestion=$menu.find('[data-elastic="suggestion"]'),
                $elasticItems =$menu.find('.elastic-item-container'),
                $selectedBox =$menu.find('.selected');

            if ($menu.find('.selected').length != 0) {
                selectedElem = true
            }
            $selectedBox.removeClass('selected');
            switch(key){
                case 40: // down arrow

                    if(!selectedElem || (!$selectedBox.hasClass('elastic-item-container') && $selectedBox.is(':last-child') )){
                        if($elasticSuggestion.length != 0) {
                            $elasticSuggestion.first().addClass('selected')
                        }else{
                            $elasticItems.first().addClass('selected')
                        }
                    }else{
                        if($selectedBox.hasClass('elastic-item-container')) {
                            if($selectedBox.parents('.result-product-container').is(':last-child')){
                                $elasticItems.first().addClass('selected')
                            }else {
                                $selectedBox.parents('.result-product-container').next().find('.elastic-item-container').addClass('selected')
                            }
                        }else{
                            $selectedBox.next().addClass('selected')
                        }
                    }
                    break
                case 38: // up arrow
                    if(!selectedElem || (!$selectedBox.hasClass('elastic-item-container') && $selectedBox.is(':first-child') )){
                        if($elasticSuggestion.length != 0) {
                            $elasticSuggestion.last().addClass('selected')
                        }else{
                            $elasticItems.last().addClass('selected')
                        }
                    }else{
                        if($selectedBox.hasClass('elastic-item-container')) {
                            if($selectedBox.parents('.result-product-container').is(':first-child')){
                                $elasticItems.last().addClass('selected')
                            }else {
                                $selectedBox.parents('.result-product-container').prev().find('.elastic-item-container').addClass('selected')
                            }
                        }else{
                            $selectedBox.prev().addClass('selected')
                        }
                    }
                    break
                case 37: //left arrow
                    if($selectedBox.hasClass('elastic-item-container')){
                        $elasticSuggestion.first().addClass('selected')
                    }else{
                        $elasticItems.first().addClass('selected')
                    }
                    break
                case 39: //right arrow
                    if($selectedBox.hasClass('elastic-item-container')){
                        $elasticSuggestion.first().addClass('selected')
                    }else{
                        $elasticItems.first().addClass('selected')
                    }
                    break
            }
        };

        this.keyupElasticSearch = function ( e ){
            switch(e.keyCode) {
                case 16: // shift
                case 17: // ctrl
                case 18: // alt
                    break
                case 40: // down arrow
                case 39: //left arrow
                case 38: // up arrow
                case 37: //left arrow
                    self.keyboardNavigation(e.keyCode,this.$menu)
                    break

                case 9: // tab
                case 13: // enter
                    if (!this.shown) return
                    var $selectedBox = this.$menu.find('.selected')
                    if ($selectedBox.length != 0 && $selectedBox.hasClass('elastic-item-container')) {
                        $selectedBox.trigger('click')
                    }else if($selectedBox.length != 0){
                        $("#product-finder-elastic").find('[data-zg-role="search-elastic-input"]').val($selectedBox.text());

                        $("#product-finder-elastic").submit();
                    }else{
                        $("#product-finder-elastic").submit();
                    }
                    break

                case 27: // escape
                    if (!this.shown) return
                    this.hide()
                    break

                default:
                    this.ajax ? this.ajaxLookup() : this.lookup()
            }

            e.stopPropagation()
            e.preventDefault()
        };

        this.blurElasticSearch = function () {
            this.focused = false
        };

        this.clickElasticSearch = function ( e ) {
            if (e.target.tagName != "LI" && e.target.tagName != 'EM'){
                return;
            }
            this.select();
            this.$element.focus();
        };

        this.startTypeAhead = function() {
            var url = window.makeUrl({module: 'eshop', action: 'autoComplete'});

            self.$search_input.typeahead({
                ajax: {
                    url: url,
                    preDispatch: function (query) {
                        self.$toggle_opacity_search.addClass('opacity-search');
                        self.$typeahead_ul[0].style.display = "block";
                        self.$typeahead_ul.addClass( 'loading show' );
                        return {
                            action: 'autoComplete',
                            key: query,
                            limit: 5,
                            orderBy : 'name',
                            sortOrder : 'ASC',
                            maxCharsForProduct: 70
                        };
                    },
                    dType : 'type',
                    displayField : 'value',
                    loadProductPageBy : 'id'
                },
                sorter : 'none',
                items  : 5,
                menu: self.$typeahead_ul,
                select: self.selectElasticSearch,
                show: self.showElasticSearch,
                hide: self.hideElasticSearch,
                render: self.renderElasticSearch,
                keyup: self.keyupElasticSearch,
                blur: self.blurElasticSearch,
                click: self.clickElasticSearch,
                item: '<div class="result-product-container"><a href="" data-elastic="link" class="elastic-item-link"><div class="elastic-item-container"><div class="elastic-img-container col-xs-2"><img src="" alt=""></div><div class="elastic-item-text-box"><div class="elastic-item-title-and-price"><div class="elastic-item-title"><span data-elastic="name"></span></div><div class="elastic-item-price"><span data-elastic="currency"></span><span data-elastic="price"></span></div></div><div class="elastic-item-desc" data-elastic="desc"></div></div></div></a><ul data-elastic="suggestions"></ul></div>',
            });
        }

        this.searchProducts = function() {
            var productName = self.$search_input.val();
            if ( !productName || productName==="" ) return false;

            var type = self.$search_input.data('type') || "";
            var id   = self.$search_input.data('id')   || "";

            // we are getting here only once. we don't need to add this to the "self" object
            self.$toggle_form.find( toggle_search_type ).val( type );
            self.$toggle_form.find( toggle_search_id ).val( id );
            self.$toggle_form.find( toggle_product_id ).val( id );
            self.$toggle_form.find( toggle_product_name ).val( productName.split(" - ")[0] );

            self.$toggle_form.find(".characteristic-key").val(productName);
        }

        this.__setEventHandlers = function () {
            self.$toggle_opacity_search.click( function(){
                self.$typeahead_ul.hide();
                self.$toggle_opacity_search.removeClass('opacity-search');
            });

            // Erase query and close input
            self.$toggle_erase_btn.click( function(){
                if(self.$search_input[0].value != "") {
                    self.$search_input[0].value = "";
                } else {
                    self.$toggle_search_container.addClass('hidden');
                    self.$toggle_search_container.removeClass('show');
                    self.$toggle_btn_search.addClass('show');
                    self.$toggle_btn_search.removeClass('hidden');
                    self.$toggle_opacity_search.removeClass('opacity-search');
                }
            });

            // Toggle the search input
            self.$toggle_btn_search.click( function(){
                self.$toggle_btn_search.addClass('hidden');
                self.$toggle_btn_search.removeClass('show');
                if( self.$toggle_search_container.hasClass( 'hidden' ) ){
                    self.$toggle_search_container.removeClass( 'hidden' );
                    self.$toggle_search_container.addClass( 'show' );
                    self.$search_input.focus();
                }
                else {
                    self.$toggle_search_container.addClass( 'hidden' );
                    self.$toggle_search_container.removeClass( 'show' );
                }
            });
        }

        this.init( element, config );
    };

    $.fn.ZG_search = function ( config ) {
        return this.each( function () {
            var $this = $(this);
            var data = $this.data('zgSearch');
            if (!data) {
                $this.data('zgSearch', (data = new ZG_Search( this, config )) );
            }
        });
    };

    // Initilize Script
    $(toggle).ZG_search( window.ZG_CONFIG );
})( jQuery );