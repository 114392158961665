/**
 * zg-pcapredic.js
 *
 * Plugin for manage e-voucher and use in cart

 *
 * @author: @author: Fabio Polizzi <fpolizzi [at] koooomo [dot] com>
 *
 */

/* global  JS_TRANSLATIONS */

(function ($, _) {
    'use strict';

    var root = this;

    /**
     * @selector The plugin start if there is the selector in the dom when the page load
     */

        //var selector = '#Register_tab';
    var selector = '#Register_tab,[data-zg-role="address-form"]';
    var control;

    var urlAjax = window.makeUrl({ module: 'eshop', action: 'getLoqate' });

    var DEFAULTS = {
        //SELECTOR
        btnFindAddress:       '[data-zg-role="find-address"]',
        countrySelect:        '[data-zg-role="country-id"]',
        stateSelect:          '[data-zg-role="state-id"]',
        inputZipCode:         '[name="shipping_address[post_code]"]',
        errorZipCode:         '[data-zg-role="error"]',
        errorZipCodeText:     '#registershipping_address_post_code-error',
        goodZipCode:          '[data-zg-role="continue"]',
        secondStep:           '[data-zg-role="second-step"]',
        selectManually:       '[data-zg-role="select-manually"]',
        selecAddresses:       '#selectedAddressId',
        cityInput:            '[data-zg-role="city"]',
        address1:             '[data-zg-role="addr_1"]',
        address2:             '[data-zg-role="addr_2"]',
        address3:             '[data-zg-role="addr_3"]',
        zipCodeError:         '#zip-code-msg',
        suggestedContenitor:  '[data-zg-role="suggestes"]',
        showAddress:          false
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var LoqateMorrisons = function ( element, options ) {

        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.$countrySelect           = $( this.options.countrySelect );
        this.$stateSelect             = $( this.options.stateSelect );

        this.__init();
        this.__eventHandlers();
    };

    LoqateMorrisons.prototype.__init = function () {
        var that = this;
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        if (isChrome == true){
            that.$element.on('focus click', this.options.inputZipCode ,  function(e){
                $( this ).attr("autocomplete","new-password");
            });
        };

        that.$element.find( that.options.countrySelect ).val("75").trigger("change");
        that.$element.find( that.options.errorZipCode ).hide();
        that.$element.find( that.options.goodZipCode ).hide();


        if (that.options.showAddress != 1){
            setTimeout(function(){
                that.$element.find( that.options.secondStep ).hide();
            }, 500);
        }else{
            $('.register-tab').trigger('click');
        }

    };



    LoqateMorrisons.prototype.__findAddressValues = function ( id ) {

        var that = this;
        $.ajax({
            url: urlAjax,
            method: 'POST',
            dataType: 'json',
            data: {
                addressId: id
            },
            success: function(data){

                $.each(data, function( k,v) {
                    $.each(v, function( kaddress,vaddress) {

                        var line1 = '';

                        if(vaddress.hasOwnProperty('Company') && vaddress.Company.length){
                            line1 = vaddress.Company + ' ';
                        }

                        line1 += vaddress.Line1;

                        that.$element.find( that.options.cityInput ).val(vaddress['City']).trigger('focusout');
                        that.$element.find( that.options.address1 ).val(line1).trigger('focusout');
                        that.$element.find( that.options.address2 ).val(vaddress['Line2']).trigger('focusout');
                        that.$element.find( that.options.address3 ).val(vaddress['Line3']).trigger('focusout');
                        that.$element.find( that.options.inputZipCode ).val(vaddress['PostalCode']).trigger('focusout');
                        that.__fix_contry( vaddress['Province'] , vaddress['CountryName'], vaddress['City'] );
                    });
                });

                that.$element.find( that.options.errorZipCode ).hide();
                that.$element.find( that.options.goodZipCode ).hide();
                that.$element.find( that.options.secondStep ).show();

            }
        });
    };


    LoqateMorrisons.prototype.__fix_contry = function ( state, country, city ) {

        var that = this;
        var data = {
            country: country || '',
            state:   state || city || ''
        };

        zgGet( 'convertPCA', data, null, {
            success: function ( response ) {
                that.$element.find( that.options.countrySelect )
                    .val( response.countryId ).trigger('focusout')
                    .trigger('change.zg.loadStates');
                that.$element.find( that.options.stateSelect ).val( response.stateId ).trigger('focusout');

                console.log(response);
            },
            error: function ( error ) {
                console.log(error);
                var val = $('option:contains(' + state +')').val();
                that.$element.find( that.options.stateSelect ).val( val ).trigger('focusout');
            }
        });
    };






    LoqateMorrisons.prototype.__eventHandlers = function () {

        var that = this;

        $( document ).on( 'click', this.options.btnFindAddress , (function ( e ) {
            e.preventDefault();

            if( that.$element.find( that.options.errorZipCodeText ).html() == '' || typeof that.$element.find( that.options.errorZipCodeText ).html() === "undefined"){
                var inputZipCode = $( this.options.inputZipCode, this.$element ).val();
                that.__Capture_Interactive_Find_v1_10( inputZipCode, '', '', '', 'GBR', '', 'EN');
            }else{
                that.$element.find( that.options.goodZipCode ).hide();
                that.$element.find( that.options.secondStep ).hide();
            }



        }).bind( this ) );

        $( document ).on( 'click', this.options.selectManually , (function ( e ) {
            e.preventDefault();
            that.$element.find( that.options.errorZipCode ).hide();
            that.$element.find( that.options.goodZipCode ).hide();
            that.$element.find( that.options.secondStep ).show();
        }).bind( this ) );

        $( document ).on( 'change', this.options.selecAddresses , (function ( e ) {
            e.preventDefault();
            var id = this.$element.find( this.options.selecAddresses ).val();
            this.__findAddressValues( id );
        }).bind( this ) );



        $( document ).on( 'click', this.options.suggestedContenitor + ' a' , (function ( e ) {
            e.preventDefault();
            var inputZipCode = $( this).attr('href');
            that.__Capture_Interactive_Find_v1_10( inputZipCode, '', '', '', 'GBR', '', 'EN');
        }));

        $( document ).on( 'zg.addressForm.ready' , (function () {
            that.__init();
        }));


/*
        $(document).on('focusin.validate focusout.validate keyup.validate','input[type="postcode"]', function (e) {
            console.log('aa');
            jQuery.validator.addMethod("postcodeUK", function (value, element) {
                return this.optional(element) || /[A-z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i.test(value);
            }, "Please specify a valid Postcode");
        });
*/


    };


    LoqateMorrisons.prototype.__Capture_Interactive_Find_v1_10 = function (Text, IsMiddleware, Container, Origin, Countries, Limit, Language) {

        var that = this;

        var $listSuggest =$('<div data-zg-role="suggestes" />');
        var isFind = false;
        var isNext = false;

        $( that.options.zipCodeError ).empty().hide();
        that.$element.find( that.options.selecAddresses ).empty().append('<option selected="selected" value="">Select an address</option>');

        $.ajax({
            url: urlAjax,
            method: 'POST',
            dataType: 'json',
            data: {
                SearchTerm: Text,
                SearchFor: 'PostalCodes',
                Country: Countries,
                LanguagePreferences: Language
            },
            success: function(data){
                $( that.options.zipCodeError ).empty().hide();
                if (data.length > 0){
                    $.each(data, function( k,v) {
                        if(v['Next'] == "Retrieve"){
                            isNext = true;
                            that.$element.find( that.options.selecAddresses ).append($('<option>', {
                                value: v['Id'],
                                text: v['Text']
                            }));
                        }
                        else{
                            isFind = true;
                            $listSuggest.append($('<a>', {
                                href: v['Id'],
                                text: v['Text']
                            }));
                        }
                    });

                    if(isFind == true){
                        $( that.options.zipCodeError ).append('<div class="error">This postcode is outside our delivery area.</div><br />CHOOSE YOUR LOCATION:').append( $listSuggest ).show();
                        that.$element.find( that.options.secondStep ).hide();
                        that.$element.find( that.options.goodZipCode ).hide();
                    }else{
                        that.$element.find( that.options.secondStep ).hide();
                        that.$element.find( that.options.goodZipCode ).show();
                    }


                }
                console.log(data);
            }
        });




    };


    // LoqateMorrisons PLUGIN DEFINITION
    // =========================
    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.loqateMorrisons' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.loqateMorrisons', new LoqateMorrisons( this, options ) );
            }
        } );
    }

    $.fn.zg_loqateMorrisons             = Plugin;
    $.fn.zg_loqateMorrisons.Constructor = LoqateMorrisons;

    //START PLUGIN
    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );

        $( document ).on( 'zg.addressForm.ready', function ( e ) {
            $( selector ).each( function () {
                Plugin.call( $( this ) );
            } );
        } );

    } );


}).call( this, jQuery, _ );