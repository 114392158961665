/**
 *
 * Plugin for manage product
 * - display product with correct configuration
 * - check quantity
 * - calculate sku
 * - add to cart
 * - add to wishlist
 * - display dreambag
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event.
 * @property {string} message - The error message.
 */

/**
 * @event document#zg.product.addedToCart Product added to cart
 * @type {object}
 * @property {array} aCustom - Array list of custom value
 * @property {int} product_id - Product id added to cart
 * @property {int} quantity - Quantity of product added to cart
 * @property {int} sku - Sku id added to cart
 */

/**
 * @event document#zg.getProductList.request-info Something went wrong. Request the cart items again
 * @type {object}
 * @property {array} products_in_cart - Array of object with list of product in cart
 * @property {string} status - Status of ajax call (for example "success")
 * @property {object} total_products - Values of total (with ship cost or not, with tax or not, ecc)
 */

/**
 * @event document#zg.product.selectedConfiguration. selected combination trigger. Used for the
 *     exchange product process
 * @type {object} data of the product
 */

/**
 * @event document#zg.product.ready Product is ready
 * @type {object} data of the product
 */

/**
 * @event document#zg.product.optionUpdated Options are ready
 * @type {object} data of the options
 */

/**
 * @event document#zg.product.addToCart. Click to add to cart button
 * @type {null}
 */

/**
 * @event document#zg.getProductInfo.productCreated Product rendered by handlebars (like in
 *     category page)
 * @type {object} See 2101-zg-getProductInfo for more info
 */

/**
 * @event document#zg.product.selectedCombination. Combination (color and size) selected
 * @type {object}
 * @property {int} pid - Product id
 * @property {int} sku - Sku id
 * @property {object} combination - List of options selected
 */

/**
 * @event document#zg.product.optionSelected.  Options selected
 * @type {object}
 */

/**
 * @event document#zg.getProductListInfo.success  When the list of product in cart are loaded check
 *     update the quantity availability checking how many product the user are in cart
 * @type {string} type - Type of list (ex "cart")
 */

/**
 * @event document#zg.urimgr.updatedUri.  The url has been update
 * @type {object}
 * @property {string} base - Url without get parameters
 * @property {object} components - Object with all components of the url
 * @property {string} hash - Hash value
 * @property {int} index - TO CHECK
 * @property {object} status - Object with all data (clearUrl,options,pid, ecc ecc)
 */

/**
 * @event document#zg.product.selectOption. Option selected
 * @type {null}
 */


/* global _, DEBUG, handlebarsTemplates, JS_TRANSLATIONS, zgGalleries, zgGet, zgPost, zgProcessProductImages */

(function ( $, _ ) {
	'use strict';

	/**
	 * @selector data-zg-role="product" The plugin start if there is the selector in the dom when
	 *     the page load
	 */
	var selector = '[data-zg-role="product"]';


	// PRODUCT CLASS DEFINITION
	// ========================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!object}     options
	 *
	 * @constructor
	 */
	var Product = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( Product.DEFAULTS );
		this.setOptions( options );

		this.product = null;

		// basic script configuration
		this.selectedSku       = this.options.selectedSku || 0;
		this.options.namespace = this.options.namespace || _.uniqueId();

		this.availability        = null;
		this.isAvailable         = false;
		this.availabilityChecked = false;

		// initialize object
		this.setEventHandlers();
	};


	/**
	 * @param {string} [elementAddToCart] Add to cart button
	 * @param {string} [elementAvailability] Div where display availability info
	 * @param {string} [elementCombination]  Ul list where display options selected
	 * @param {string} [elementDreamBag] Div where is form for dream bag (if sku is out of stock
	 *     you can put your email for inform you when return in stock)
	 * @param {string} [elementDreamBagEmail] Input where the user can put the mail for dream bag
	 * @param {string} [elementOptionSelector] Ul contains the list of the options
	 * @param {string} [elementPrice] Container of product price
	 * @param {string} [elementQuantity] Input for change quantity product to buy
	 * @param {string} [elementLink] Link of product details (used in category page or order page)
	 * @param {array}  [selectedOptions] Array of options to select on init (used for example if
	 *     you select color in category and go to product details)
	 * @param {int}    [selectedSku] Id of sku selected. Used in cart page
	 * @param {int}    [disabledSkus] If the current SKU has been 'disabled' we don't allow to
	 *     continue. This is useful for the 'product exchange' (don't allow to exchange a product
	 *     with itself)
	 * @param {array} [disableOptions] Disable all options values not assigned to any SKU. If true
	 *     it will be applied to all product options. You can also use an array of product options
	 *     ids.
	 * @param {boolean} [enableAddToCart] If false disable add to cart
	 * @param {boolean} [enableFloatQuantity] If true enable float quantity
	 * @param {boolean} [isAllOptionsSelected]
	 * @param {boolean} [selectDefaultOption] If true select default options on init
	 * @param {boolean} [selectFirstOption] If true select first options on init
	 * @param {boolean} [checkQuantity] Setting this to false will allow you to add products to the
	 *     cart without checking if they are available.
	 * @param {boolean} [processImages] Process the crazy back-end structure for the images and
	 *     creates something actually usable. Should be true only for the product page and maybe
	 *     the quickbuy.
	 * @param {boolean} [checkAvailabilityOnInit] If true check the availability on init
	 * @param {boolean} [namespace] Used in return table only for specific unique name
	 * @param {string} [templatePrice] Handlebars template for product price
	 * @param {boolean} [updateUri] If true update current page url and/or quickbuy/product page
	 *     opening options
	 * @param {boolean} [productReloadPageOnAdd] If true when the user add to cart the product,
	 *     reload the page
	 */
	Product.DEFAULTS = {
		elementAddToCart:      '[data-zg-role="add-to-cart"]',
		elementAvailability:   '[data-zg-role="availability"]',
		elementCombination:    '[data-zg-role="selected-combination"]',
		elementDreamBag:       '[data-zg-role="dreambag"]',
		elementDreamBagEmail:  '[data-zg-role="dreambag-email"]',
		elementOptionSelector: '[data-zg-role="option-selector"]',
		elementPrice:          '[data-zg-role="product-price"]',
		elementQuantity:       '[data-zg-role="quantity"]',
		elementLink:           '[data-zg-role="product-link"]',

		selectedOptions: [],
		selectedSku:     0,
		disabledSkus:    null,

		disableOptions: true,

		enableAddToCart:     true,
		enableFloatQuantity: false,

		isAllOptionsSelected: false,
		selectDefaultOption:  true,
		selectFirstOption:    false,

		checkQuantity: true,

		processImages: false,

		checkAvailabilityOnInit: true,

		namespace: null,

		templatePrice: 'product-price',

		updateUri: false,

		productReloadPageOnAdd: false
	};


	/**
	 * If the product is available it triggers the request to add to the cart.
	 * Otherwise it requests the availability (if unknown) or displays an error msg.
	 *
	 * @method addToCart
	 * @fires document#zg-error the product is not available.
	 */
	Product.prototype.addToCart = function () {
		if ( this.product ) {
			if ( this.isAllOptionsSelected() ) {
				// check if the product is available
				if ( this.isAvailable ) {
					this.addToCartRequest();
				} else if ( this.availabilityChecked ) {
					// the product is not available.
					$( document ).trigger( 'zg-error', [{
						eventType: 'add-to-cart.not-available',
						message:   window.JS_TRANSLATIONS['Out of Stock']
					}] );
				} else {
					// the availability has not been checked yet.
					// We call the check and if it is successful addToCart will be triggered again
					// (once).
					this.$element.off( 'product-updated-availability.addtocart' );
					this.$element.one( 'product-updated-availability.addtocart', (function () {
						this.addToCart();
					}).bind( this ) );

					this.checkAvailability();
				}
			} else {
				// we don't have a selected SKU
				this.missingOptionMsg();
			}
		}
	};


	/**
	 * @method addToCartRequest
	 * @fires document#zg-error Custom product make an error
	 */

	/**
	 * @method addToCartRequest
	 * @fires document#zg.product.addedToCart Product added to the cart
	 */

	/**
	 * @method addToCartRequest
	 * @fires document#zg.product.selectedConfiguration. Selected combination trigger. Used for the
	 *     exchange product process
	 */

	/**
	 * Adds the product to the cart.
	 *
	 */
	Product.prototype.addToCartRequest = function () {
		var addToCart;
		var callback;
		var customValues;
		var data;
		var i;
		var value;

		data = {
			product_id: this.product.id,
			sku:        this.selectedSku,
			quantity:   this.cleanQuantity() || 1
		};

		if ( this.product && this.options.enableAddToCart ) {
			addToCart = this.options.enableAddToCart;

			// For custom products:
			if ( this.product.custom_values ) {
				customValues = [];
				value        = null;

				for ( i = 0; i < this.product.custom_values.length && addToCart; i++ ) {
					value = this.$element.find( '[name="acustom_' + this.product.custom_values[i].id + '"]' ).val() || null;

					if ( value ) {

						customValues.push( {
							customization_id:    this.product.custom_values[i].id,
							customization_value: value
						} );

					} else if ( this.product.custom_values[i].is_mandatory ) {
						addToCart = false;

						$( document ).trigger( 'zg-error', [{
							eventType: 'custom-product',
							message:   JS_TRANSLATIONS.product_customizationError
						}] );
					}
				}

				data = $.extend( {}, data, { aCustom: customValues } );
			}

			// Add to the cart
			if ( addToCart ) {
				callback = {
					success: (function ( data, status ) {
						// product successfully added  \o/
						this.cleanQuantity( 1 );

						$( document ).trigger( 'zg.product.addedToCart', [status, this.product, data] );

						if ( this.options.productReloadPageOnAdd ) {
							window.location.reload();
						}
					}).bind( this, data ),

					error: function () {
						// Something went wrong. Request the cart items again.
						$( document ).trigger( 'zg.getProductList.request-info', ['cart'] );
					}
				};

				// disable the addToCart element.
				$( this.options.elementAddToCart, this.$element ).prop( 'disabled', true );

				zgPost( 'addProductToCart', data, null, callback );
			}
		}

		// selected combination trigger. Used for the exchange product process
		$( document ).trigger( 'zg.product.selectedConfiguration.' + this.options.namespace, [data] );
	};


	/**
	 *
	 *
	 */
	Product.prototype.calculateSku = function () {
		var sku;

		if ( this.product && this.product.skus ) {
			this.selectedSku = 0;

			for ( sku in this.product.skus ) {
				if (
					this.product.skus.hasOwnProperty( sku ) &&
					this.isValidSku( sku ) &&
					this.isSelectedSku( sku )
				) {
					this.selectedSku = sku;
					break;
				}
			}
		} else if ( DEBUG ) {
			console.warn( 'sku information missing for product ' + this.product.id );
		}
	};


	/**
	 * Check if the product is currently available in the store.
	 *
	 * Based on the selected SKU and the quantity, make sure that the amount
	 * requested by the user is available in the store.
	 *
	 */
	Product.prototype.checkAvailability = function () {
		var data;
		var callbacks;

		// disable add to cart
		this.setAvailability( null );
		this.updateButton( false );

		// if the options are not selected we don't continue and leave the button as disabled
		if ( this.product && this.isAllOptionsSelected() ) {
			if ( !this.options.checkQuantity ) {
				// We are not supposed to check the availability
				// Re-enable the button (we just checked that the options are selected)
				this.setAvailability( {} );
			} else if ( this.options.disabledSkus && _.contains( this.options.disabledSkus, this.selectedSku ) ) {
				// If the current SKU has been 'disabled' we don't allow to continue
				// This is useful for the 'product exchange' (don't allow to exchange a product
				// with itself)
				this.setAvailability( {} );
			} else {
				data = {
					product_id: this.product.id,
					sku:        this.selectedSku || 0,
					quantity:   (this.cleanQuantity() + this.quantityInCart()) || 1
				};

				callbacks = {
					success: (this.setAvailability).bind( this )
				};

				$( this.options.elementAvailability, this.$element )
					.hide()
					.empty();

				zgGet( 'checkProductAvailability', data, null, { success: _.bind( this.setAvailability, this )});
			}
		}
	};


	/**
	 * This will change the value of the quantity inputs.
	 * Make sure the quantity interface is a number and is in the acceptable range of values
	 * Returns the validated value
	 *
	 * @param {number=} quantity
	 *
	 * @returns {number}
	 */
	Product.prototype.cleanQuantity = function ( quantity ) {
		var val       = quantity || 1;
		var $qtyInput = this.$element.find( this.options.elementQuantity );

		if ( $qtyInput.length ) {
			val = $qtyInput.zg_cleanQuantity( quantity, this.options.enableFloatQuantity ).val();
		}

		// return the cleaned value
		return Number(val);
	};


	/**
	 * Get the first option based on its name.
	 * Uses the same sorting function that we use in skeleton to sort the options before rendering
	 * the HTML
	 *
	 * @param {number|string} option
	 *
	 * @returns {number|null}
	 */
	Product.prototype.getFirstValidValue = function ( option ) {
		var i;
		var value;
		var values;

		value = null;

		if ( this.product && this.product.options && this.product.options[option] ) {
			values = window.zgSortObjectByProp( this.product.options[option].values );

			for ( i = 0; i < values.length && !value; i++ ) {
				if ( this.isValidOption( option, +(values[i].key) ) ) {
					value = values[i].key;
				}
			}
		}

		return value;
	};


	/**
	 *
	 * @param {object=} object
	 *
	 * @returns {array|null}
	 */
	Product.prototype.getImagesGallery = function ( object ) {
		var images = null;

		if ( object ) {
			if ( object.processedImages ) {
				// use the processed images
				images = object.processedImages;
			} else if ( object.images ) {
				if ( this.options.processImages ) {
					// process the images and store them to be used again
					images = zgProcessProductImages( object.images );
					object.processedImages = images;
				} else {
					// send the original images in an array
					images = [object.images];
				}
			}
		}

		return images;
	};


	/**
	 *
	 * @param {string} [key]
	 *
	 * @returns {array|null}
	 */
	Product.prototype.getImagesThreeSixty = function ( key ) {
		var images = null;

		if ( this.product && this.product.images360 ) {
			if ( key && this.product.images360.option && this.product.images360.option[key] ) {
				images = this.product.images360.option[key];
			} else if ( this.product.images360['default'] ) {
				images = this.product.images360['default'];
			}
		}

		return images;
	};


	/**
	 * Returns the first option value that has a specific main_option value.
	 * Not very consistent across browsers, but we don't really need more than this.
	 *
	 * @param optionId
	 * @param main
	 *
	 * @returns {*}
	 */
	Product.prototype.getOptionFromMainOption = function ( optionId, main ) {
		var value = null;
		var index;

		if (
			this.product &&
			this.product.options &&
			this.product.options[optionId] &&
			this.product.options[optionId].values
		) {
			for ( index in this.product.options[optionId].values ) {
				// select the first value belonging to that main option
				if (
					this.product.options[optionId].values.hasOwnProperty( index ) &&
					this.product.options[optionId].values[index].main_options &&
					_.contains( this.product.options[optionId].values[index].main_options, +(main) )
				) {
					value = index;
					break;
				}
			}
		}

		return value;
	};


	/**
	 * returns an array with the id(s) of the currently selected options
	 *
	 * @returns {Array}
	 */
	Product.prototype.getSelectedOptionsArray = function () {
		var options = [];
		var option;

		if ( this.product ) {
			if (
				this.selectedSku &&
				this.product.skus &&
				this.product.skus[this.selectedSku]
			) {
				for ( option in this.product.skus[this.selectedSku].options ) {
					if ( this.product.skus[this.selectedSku].options.hasOwnProperty( option ) ) {
						options.push( this.product.skus[this.selectedSku].options[option] );
					}
				}
			} else if ( this.product.options ) {
				for ( option in this.product.options ) {
					if (
						this.product.options.hasOwnProperty( option ) &&
						this.product.options[option].selectedValue
					) {
						options.push( this.product.options[option].selectedValue );
					}
				}
			}
		}

		return options;
	};


	/**
	 *
	 * @param optionId
	 *
	 * @returns {*}
	 */
	Product.prototype.getSelectedValue = function ( optionId ) {
		var selectedValue = null;

		if (
			this.product &&
			this.product.options &&
			this.product.options[optionId] &&
			this.product.options[optionId].selectedValue
		) {
			selectedValue = this.product.options[optionId].selectedValue;
		}

		return selectedValue;
	};


	/**
	 *
	 * @returns {boolean}
	 */
	Product.prototype.isAllOptionsSelected = function () {
		var selected = true;
		var option;

		if ( !this.product ) {
			// there needs to be a product for it to have selected options  ;)
			selected = false;
		} else if ( this.selectedSku ) {
			// there is an sku selected.
			selected = true;
		} else if ( !this.product.options && !this.skus ) {
			// there is no sku selected but the product has no options or SKUs.
			// for unique product without any options.
			selected = true;
			// I'm really not sure about this behaviour (maybe we just didn't request them?) but it
			// was the behavior in the old product script ( and it was even more unreliable there )
		} else {
			// go through the product options and check if all of them have a selected value
			for ( option in this.product.options ) {
				if ( this.product.options.hasOwnProperty( option ) && !this.getSelectedValue( option ) ) {
					selected = false;
					break;
				}
			}
		}

		return selected;
	};


	/**
	 *
	 * @param {number} option
	 * @param {number} value
	 *
	 * @returns {boolean}
	 */
	Product.prototype.isValidOption = function ( option, value ) {
		var opt;
		var sku;
		var valid;
		var selectedValue;

		// initialise valid as true only if option and value are defined
		valid = ( option && value );

		// I the product object doe not have SKUs we return the current status of valid.
		if ( valid && this.product.skus ) {
			// We assume that the value is not valid until we find it in a sku
			valid = false;

			// check the SKUs
			for ( sku in this.product.skus ) {
				if (
					!valid &&
					this.product.skus.hasOwnProperty( sku ) &&
					this.isValidSku( sku ) &&
					( this.product.skus[sku].options && this.product.skus[sku].options[option] === value )
				) {
					// The option value is valid (present in the sku object)
					valid = true;

					//
					for ( opt in this.product.skus[sku].options ) {
						if ( this.product.skus[sku].options.hasOwnProperty( opt ) && opt != option ) {
							selectedValue = this.getSelectedValue( opt );

							if ( selectedValue && this.product.skus[sku].options[opt] != selectedValue ) {
								valid = false;
							}
						}
					}
				}
			}
		}

		return valid;
	};


	/**
	 *
	 * @param {string|number} sku
	 *
	 * @returns {boolean}
	 */
	Product.prototype.isValidSku = function ( sku ) {
		var valid = false;

		if (
			sku &&
			this.product &&
			this.product.skus &&
			this.product.skus[sku] &&
			( !_.isBoolean( this.product.skus[sku].is_available ) || this.product.skus[sku].is_available )
		) {
			valid = true;
		}

		return valid;
	};


	/**
	 *
	 * @param {string|number} sku - ID to test
	 *
	 * @returns {boolean}
	 */
	Product.prototype.isSelectedSku = function ( sku ) {
		var option;
		var options;
		var selected = false;

		if ( this.product && this.product.skus && this.product.skus[sku] ) {
			options  = this.product.skus[sku].options;
			selected = true;

			for ( option in options ) {
				if (
					options.hasOwnProperty( option ) &&
					options[option] !== this.getSelectedValue( option )
				) {
					selected = false;
					break;
				}
			}
		}

		return selected;
	};


	/**
	 *
	 *
	 */
	Product.prototype.missingOptionMsg = function () {
		// TODO: improve message listing unselected options

		$( document ).trigger( 'zg-error', [{
			eventType: 'product.notSelectedSku',
			message:   JS_TRANSLATIONS.genericErrorMsg
		}] );
	};


	/**
	 * Initialize the options selecting a default value
	 *
	 */
	Product.prototype.selectDefaultOptions = function () {
		var option;
		if ( this.product && this.product.options ) {
			if ( this.isValidSku( this.options.selectedSku )) {
				// there is a default sku. select the option values from it
				for ( option in this.product.skus[this.options.selectedSku].options ) {
					if ( this.product.skus[this.options.selectedSku].options.hasOwnProperty( option ) ) {
						this.updateSelectedValues( option, this.product.skus[this.options.selectedSku].options[option] );
					}
				}
			} else {
				// Go through the options object twice so we can select the color first.
				// Not too bad as the products usually just have 1 to 3 options

				// select image options first
				for ( option in this.product.options ) {
					if (
						this.product.options.hasOwnProperty( option ) &&
						this.product.options[option].has_image
					) {
						this.selectDefaultOptionValue( option );
					}
				}

				// select other options
				for ( option in this.product.options ) {
					if ( this.product.options.hasOwnProperty( option ) && !this.product.options[option].has_image ) {
						this.selectDefaultOptionValue( option );
					}
				}
			}
		}
	};


	/**
	 * Initialize the options selecting a default value
	 *
	 * priority is as follows:
	 * - product.selectedOptions
	 * - url
	 * - default options
	 * - first value in the option
	 *
	 * If an option has only one value it will be selected by default
	 *
	 * @param option
	 */
	Product.prototype.selectDefaultOptionValue = function ( option ) {
		var values;
		var value;
		var optionsFromUri;

		if ( this.product && this.product.options && this.product.options[option] ) {
			optionsFromUri = $.uriMgr( { action: 'getStatus' } ).components;
			values         = _.keys( this.product.options[option].values );
			value          = null;

			// if there is only one value. we select it
			if ( values.length === 1 ) {
				value = values[0];
			}

			// set the default options from an array (from html data attr)
			if ( !value && this.options.selectedOptions && this.options.selectedOptions.length ) {
				value = this.selectOptions( option, this.options.selectedOptions );
			}

			// set the default options from an array (from script)
			if ( !value && this.product.selectedOptions && this.product.selectedOptions.length ) {
				value = this.selectOptions( option, this.product.selectedOptions );
			}

			// set the default options from uri (from product page)
			if ( !value && optionsFromUri && optionsFromUri.options ) {
				value = this.selectOptions( option, optionsFromUri.options );
			}

			// set the default options from uri (from category filters)
			if ( !value && optionsFromUri && optionsFromUri['opt_' + option] ) {
				value = this.selectOptions( option, optionsFromUri['opt_' + option] );
			}

			// set the default options from an array
			if ( !value && this.options.selectDefaultOption && !_.isEmpty( this.product.default_options ) ) {
				value = this.selectOptions( option, this.product.default_options );
			}

			// the value is not in default options. try to select the first option instead
			if ( !value && this.options.selectFirstOption ) {
				value = this.getFirstValidValue( option );
			}

			if ( value ) {
				// select the value but do not update the url / links
				this.updateSelectedValues( option, value, true );
			}
		}
	};


	/**
	 *
	 * @param option
	 * @param values
	 *
	 * @returns {*}
	 */
	Product.prototype.selectOptions = function ( option, values ) {
		var i;
		var value = null;

		if ( !_.isArray( values ) ) {
			values = values.split( ',' );
		}

		for ( i = 0; i < values.length && !value; i++ ) {
			if ( this.product.options[option].values[values[i]] ) {
				value = values[i];
			} else if (
				this.product.main_options &&
				this.product.main_options[values[i]]
			) {
				value = this.getOptionFromMainOption( option, values[i] );
			}

			if ( !this.isValidOption( option, +value ) ) {
				value = null;
			}
		}

		//
		return value;
	};


	/**
	 *
	 * @param {?Object}  availability
	 */
	Product.prototype.setAvailability = function ( availability ) {
		this.availability        = availability;
		this.availabilityChecked = !!availability;

		if ( this.availabilityChecked ) {
			this.updateAvailability();
			this.$element.trigger( 'product-updated-availability' );
		} else {
			this.isAvailable = false;
		}
	};


	/**
	 * @method setEventHandlers
	 * @listen document#zg.product.addToCart. When the user click on Add to Cart button call
	 *     addToCart function
	 */
	/**
	 * @method setEventHandlers
	 * @listen document#zg.product.selectOption. On select option call the function
	 *     updateSelectedValues
	 */
	/**
	 * @method setEventHandlers
	 * @listen document#zg.getProductListInfo.success When the list of product in cart are loaded
	 *     check update the quantity availability checking how many product the user are in cart
	 */
	/**
	 * @method setEventHandlers
	 * @listen document#zg.urimgr.updatedUri.  When the url has been updated update the product info
	 */
	/**
	 * @method setEventHandlers
	 * @listen document#zg.product.optionSelected. COMMENTED update selected option interface
	 */
	Product.prototype.setEventHandlers = function () {
		// Add to cart (button)
		this.$element.on( 'click.zg.product.addToCart', this.options.elementAddToCart, (function ( e ) {
			e.preventDefault();
			this.addToCart();
		}).bind( this ) );

		// Add to cart (event)
		$( document ).on( 'zg.product.addToCart.' + this.options.namespace, this.addToCart );

		// ---------------------------------------------------------------------

		// // Apply loading class to form
		// this.$element.on('submit', 'form[data-loading-overlay]', function applyLoading(e){
		// 	$(e.target).addClass('loading');
		// });

		// Option Selector - link version
		this.$element.on(
			'click.zg.product.selectOption',
			this.options.elementOptionSelector + ' [data-value]',
			(function ( e ) {
				var $elem = $( e.currentTarget );
				var $container = $elem.closest( this.options.elementOptionSelector );

				e.preventDefault();

				this.updateSelectedValues( $container.data( 'option-id' ), $elem.data( 'value' ) );
			}).bind( this )
		);

		// Option Selector - select version
		this.$element
			.on( 'change.zg.product.selectOption', 'select' + this.options.elementOptionSelector, (function ( e ) {
				var $elem = $( e.currentTarget );
				this.updateSelectedValues( $elem.data( 'option-id' ), $elem.val());
			}).bind( this ))
			.on( 'focusin.zg.product.selectOption', 'select', function () {
				// disable the empty options (please select / option name) when you open the select
				$( this ).find( 'option[value=""]' ).prop( 'disabled', true );
				this.updateSelectedValues( $container.data( 'option-id' ), $elem.data( 'value' ));
			})
			.on( 'focusout.zg.product.selectOption', 'select', function () {
				// re-enable them again so it doesn't auto-select options on close
				$( this ).find( 'option[value=""]' ).prop( 'disabled', false );
				this.updateSelectedValues( $container.data( 'option-id' ), $elem.data( 'value' ) );
			} );

		// Option Selector - EVENT version
		//$( document ).on( 'zg.product.selectOption.' + this.options.namespace, (function ( e, obj ) {
		//	this.updateSelectedValues( obj.optionId, obj.value );
		//}).bind( this ) );

		// ---------------------------------------------------------------------

		// update selected option interface
		//$( document ).on( 'zg.product.optionSelected.' + this.options.namespace, (function ( e, obj ) {
		//	this.updateOptionsInterface( obj );
		//}).bind( this ) );

		// ---------------------------------------------------------------------

		this.$element.on( 'change.zg.product', this.options.elementQuantity, (function () {
			this.updateAvailability();
		}).bind( this ) );

		$( document ).on( 'zg.getProductListInfo.success', (function ( e, type ) {
			if ( type === 'cart' ) {
				this.updateAvailability();
			}
		}).bind( this ) );

		// ---------------------------------------------------------------------

		// the url has been updated
		$( document ).on( 'zg.urimgr.updatedUri.' + this.options.namespace, (function ( e, info ) {
			if (
				this.options.updateUri &&
				info &&
				info.status &&
				info.status.data &&
				info.status.data.trigger === 'Product' &&
				info.status.data.pid !== this.product.id
			) {
				this.$element.closest( '[data-zg-role="get-product"]' ).trigger( 'UpdateProductInfo', [{ products: [info.status.data.pid] }] );
			}
		}).bind( this ) );

		// ---------------------------------------------------------------------

		$( this.options.elementDreamBag, this.$element ).on( 'submit.zg.product.dreamBag', (function ( e ) {
			e.preventDefault();

			zgPost(
				'createProductAvailabilityRequest',
				{
					product_id: this.product.id,
					sku:        this.selectedSku || 0,
					email:      $( e.currentTarget ).find( this.options.elementDreamBagEmail ).val()
				}
			);
		}).bind( this ) );
	};


	/**
	 *
	 * @param {object} options
	 */
	Product.prototype.setOptions = function ( options ) {
		_.extendOwn( this.options, options );

		// cast disabledSkus to array
		if ( _.isString( this.options.disabledSkus ) ) {
			this.options.disabledSkus.split( ',' );
		}

		// make sure that disabledSkus' items are strings
		if ( _.isArray( this.options.disabledSkus ) ) {
			this.options.disabledSkus = _.map( this.options.disabledSkus, function ( item ) {
				return '' + item;
			} );
		}

		// disableOptions should be either boolean or Array
		if ( _.isString( this.options.disableOptions ) ) {
			this.options.disableOptions.split( ',' );
		}

		if ( _.isArray( this.options.disableOptions ) ) {
			this.options.disableOptions = _.map( this.options.disableOptions, function ( item ) {
				if ( !isNaN( item ) ) {
					item = +item;
				}

				return item;
			} );
		}
	};


	/**
	 * Invoked after the product script has been initialized and the event handlers are set.
	 * It will set up the product info for the script updating the seo content, selected options,
	 * ...
	 */

	/**
	 * @method setProductInfo
	 * @fires document#zg.product.ready The product was ready (gallery is initialized, options are configured, availability is check)
	 */

	Product.prototype.setProductInfo = function ( product ) {
		if ( product ) {
			this.product = product;

			this.availability        = null;
			this.isAvailable         = false;
			this.availabilityChecked = false;

			this.updateSEO();

			// initialize the product gallery
			zgGalleries(
				this.$element,
				this.options,
				this.getImagesGallery( product ),
				this.getImagesThreeSixty(),
				{
					productName: product.name
				}
			);

			// disable invalid options
			this.updateDisabledOptions();

			// select the default options
			this.selectDefaultOptions();

			// the product has no options so the availability would not be triggered
			if ( !product.options && this.options.checkAvailabilityOnInit ) {
				this.checkAvailability();
			}

			$( document ).trigger( 'zg.product.ready', [this] );
		} else if ( DEBUG ) {
			console.warn( 'setProductInfo: No product object provided' );
		}
	};


	/**
	 *
	 *
	 */
	Product.prototype.updateAvailability = function ( quantity, cartQuantity ) {
		var availability;
		var remaining;
		var selectedQuantity;

		if ( this.product ) {
			quantity     = _.isNumber( quantity ) ? quantity : this.cleanQuantity() || 1;
			cartQuantity = _.isNumber( cartQuantity ) ? cartQuantity : this.quantityInCart();

			if ( this.availability && this.availabilityChecked ) {
				if ( this.options.checkQuantity ) {
					this.isAvailable = false;

					availability = {
						cartQuantity: cartQuantity,
						isAvailable:  false
					};

					if (
						this.options.disabledSkus &&
						_.contains( this.options.disabledSkus, this.selectedSku )
					) {

						// disabled sku
						availability.isAvailable = false;
						availability.msg         = JS_TRANSLATIONS['product.disabledSku'];

					} else if ( this.availability.quantity_class === 'OnDemand' || !this.availability.quantity ) {

						// on demand or out of stock
						availability.isAvailable = this.availability.enableAddToCart;
						availability.msg         = this.availability.quantity_name;

					} else {

						// calculate remaining units based on current quantities
						remaining = Math.min( quantity, Math.max( (this.availability.quantity - cartQuantity), 0 ) );

						//availability.quantity    = this.availability.quantity;
                        availability.quantity    = ' ';

						availability.remaining   = remaining;

						selectedQuantity = this.cleanQuantity( remaining );

						availability.isAvailable = (
							this.availability.enableAddToCart &&
							this.availability.quantity > 0 &&
							remaining > 0 &&
							selectedQuantity > 0 &&
							selectedQuantity <= remaining
						);
					}

					// set up product availability
					this.isAvailable = availability.isAvailable;

					// show the availability message
					$( this.options.elementAvailability, this.$element )
						.html( handlebarsTemplates.render( 'product-availability', availability ) )
						.fadeIn();

					// if no more available, disable the current selection
					if( availability && ( availability.isAvailable === false)){
						this.$element
							.find('[data-option-id="2"] a.active, [data-option-id="2"] a.selected')
							.removeClass('active selected')
							.addClass('lineThrough');
					}

				} else {
					// The Product is not supposed to check availability
					this.isAvailable = true;
				}

				// enable or disable Add to cart button
				this.updateButton();
			} else {
				// The availability information is not available
				// ( sorry, I feel awful just looking at that sentence, but I couldn't resist )
				// We request the availability.
				// That process will trigger the current function again.
				this.checkAvailability();
			}
		}
	};


	/**
	 *
	 * @param {boolean} [isAvailable]
	 */
	Product.prototype.updateButton = function ( isAvailable ) {
		if ( _.isNull( isAvailable ) || _.isUndefined( isAvailable ) ) {
			isAvailable = this.isAvailable;
		}

		// enable or disable Add to cart button
		$( this.options.elementAddToCart, this.$element ).prop( 'disabled', !isAvailable );

		// show or hide the "dream bag" form
		if ( this.isAvailable || this.quantityInCart() > 0 ) {
			$( this.options.elementDreamBag, this.$element ).fadeOut();
		} else if ( this.availabilityChecked ) {
			$( this.options.elementDreamBag, this.$element ).fadeIn();
		}
	};


	/**
	 * Disables invalid (not available) option values.
	 *
	 * If you set a optionId and value the disabled options will be in relation
	 * with that option value
	 *
	 * @method updateDisabledOptions
	 * @fires document#zg.product.optionUpdated Options are ready
	 */
	Product.prototype.updateDisabledOptions = function () {
		var canDisableOption;
		var enable;
		var i;
		var opt;
		var options;
		var sku;
		var val;

		if ( this.options.disableOptions && this.product && this.product.options && this.product.skus ) {
			options = _.keys( this.product.options );

			// we are only enabling options based on the value of other options
			// Do not do all this if the current product only has one option
			if ( options.length > 1 ) {
				canDisableOption = (function ( opt ) {
					var res = false;

					if (
						this.options.disableOptions === true ||
						( _.isArray( this.options.disableOptions ) && _.contains( this.options.disableOptions, opt ) )
					) {
						res = true;
					}

					return res;
				}).bind( this );

				enable = (function ( opt, val ) {
					$( this.options.elementOptionSelector, this.$element ).each( function () {
						var $option = $( this );

						if ( !opt || $option.data( 'option-id' ) === opt ) {
							// select version
							$option.find( 'option[value="' + val + '"]' ).prop( 'disabled', false );

							// 'click' version
							$option.find( '[data-value="' + val + '"]' ).removeClass( 'disabled' );
						}
					} );
				}).bind( this );

				// set all option values as disabled
				$( this.options.elementOptionSelector, this.$element ).each( function () {
					var $option = $( this );
					var opt     = +( $option.data( 'option-id' ) );

					if ( canDisableOption( opt ) ) {
						// select version
						$option.find( 'option' ).prop( 'disabled', true );

						// 'click' version
						$option.find( '[data-value]' ).addClass( 'disabled' );
					}
				} );

				for ( i = 0; i < options.length; i++ ) {
					val = null;

					if ( this.product.options[( options[i] )].selectedValue ) {
						val = +(this.product.options[( options[i] )].selectedValue);
					}

					// re-enable available options
					for ( sku in this.product.skus ) {
						if (
							this.product.skus.hasOwnProperty( sku ) &&
							this.isValidSku( sku ) &&
							this.product.skus[sku].options
						) {
							// enable all the available values
							if ( !val || this.product.skus[sku].options[( options[i] )] === +val ) {

								for ( opt in this.product.skus[sku].options ) {
									if (
										this.product.skus[sku].options.hasOwnProperty( opt ) &&
										opt !== options[i]
									) {
										enable( +(opt), this.product.skus[sku].options[opt] );
									}
								}
							}
						}
					}
				}

				// enable all "empty" options (please select)
				enable( null, '' );
			}
		}

		// Options are ready
		$( document ).trigger( 'zg.product.optionUpdated', [this] );
	};


	/**
	 * Updates the selected option interface
	 *
	 */
	Product.prototype.updateOptionsInterface = function ( obj ) {
		// set options as selected and deselect the previous ones
		$( this.options.elementOptionSelector, this.$element )
			// select only the current option
			.filter( '[data-option-id="' + obj.optionId + '"]' )
			// for each option update the selected value interface
			.each( function () {
				var $this = $( this );

				if ( $this.is( 'select' ) ) { // select version
					$this.val( obj.value );
				} else { // list version
					$this.find( '[data-value]' ).removeClass( 'active' );
					$this.find( '[data-value="' + obj.value + '"]' ).addClass( 'active' );
				}
			} );

		// update selected option label and image
		if (
			this.product.options[obj.optionId] &&
			this.product.options[obj.optionId].values &&
			this.product.options[obj.optionId].values[obj.value]
		) {
			if ( this.product.options[obj.optionId].values[obj.value].name ) {
				this.$element
					.find( '[data-zg-option-label="' + obj.optionId + '"]' )
					.text( this.product.options[obj.optionId].values[obj.value].name );
			}

			if ( this.product.options[obj.optionId].values[obj.value].images ) {
				this.$element
					.find( '[data-zg-option-image="' + obj.optionId + '"]' )
					.html( handlebarsTemplates.render(
						'image',
						{ image: this.product.options[obj.optionId].values[obj.value].images.color }
					) );
			}
		}
	};


	/**
	 * Update the product interface.
	 * this is just a wrapper to call other functions :)
	 *
	 * enable/disable add to cart
	 * update "selected combination"
	 * update price
	 *
	 * @param {boolean=} stopUriUpdate
	 */
	Product.prototype.updateProductInterface = function ( stopUriUpdate ) {
		this.checkAvailability();
		this.updatePrice();
		this.updateSelectedCombination();

		// update current page url and/or quickbuy/product page opening options
		if ( !stopUriUpdate ) {
			this.updateURL();
		}
	};


	/**
	 * Set the price for the current selected SKU.
	 *
	 * TODO: discount element -> '[data-zg-role="product-discount-percentage"]'
	 */
	Product.prototype.updatePrice = function () {
		var $price;
		var calculatedDiscount;
		var price;
		var promoDiscount;

		if ( this.product ) {
			price  = this.product.price;
			$price = this.$element.find( this.options.elementPrice );

			if (
				this.selectedSku &&
				this.product.skus &&
				this.product.skus[this.selectedSku] &&
				this.product.skus[this.selectedSku].price
			) {
				price = this.product.skus[this.selectedSku].price;

				if ( !price.currency_symbol ) {
					price.currency_symbol = this.product.price.currency_symbol;
				}
			}

			if ( price && price.to_discount > 0 && !price.discount_percentage ) {
				calculatedDiscount = ( 1 - ( price.sell / price.to_discount ) ) * 100;
				promoDiscount = null;

				price.discount_percentage = Math.floor( promoDiscount || calculatedDiscount );
			}

			$price.html( handlebarsTemplates.render( this.options.templatePrice, price ) );

		}
	};


	/**
	 * @method updateSelectedCombination
	 * @fires document#zg.product.selectedCombination. Options combination selected
	 */
	Product.prototype.updateSelectedCombination = function () {
		if ( this.product ) {

			// search any combination element
			var $container  = $( this.options.elementCombination );
			var combination = [];
			var option;

			if (
				$container.length > 1 &&
				this.options.elementCombination === Product.DEFAULTS.elementCombination
			) {
				// If there is more than one element, use only the one inside the current product
				// element but only if we are not targeting a specific selector
				$container = $( this.options.elementCombination, this.$element );
			}

			// update the contents
			for ( option in this.product.options ) {
				if (
					this.product.options.hasOwnProperty( option ) &&
					this.product.options[option].selectedValue
				) {
					combination.push( {
						option: this.product.options[option].name,
						value:  this.product.options[option].values[( this.product.options[option].selectedValue )].name
					} );
				}
			}

			// Append the selected combination to the containers
			$container.each( function () {
				$( this )
					.hide()
					.removeClass( 'hidden' )
					.html( handlebarsTemplates.render( 'selected-combination-item', combination ) )
					.fadeIn();
			} );

			$( document ).trigger(
				'zg.product.selectedCombination.' + this.options.namespace,
				[{
					pid:         this.product.id,
					sku:         this.selectedSku,
					combination: combination
				}]
			);
		}
	};


	/**
	 * Updates the selected option values in the object
	 *
	 * Triggers an event once the option has been selected.
	 * That event is used to update the interface (display selected option, gallery, ...)
	 */

	/**
	 * @method updateSelectedValues
	 * @fires document#zg.product.optionSelected. Options selected
	 */

	Product.prototype.updateSelectedValues = function ( optionId, value, stopUriUpdate ) {
		var optionData;

        if ( optionId && !value ) {
            this.product.options[optionId].selectedValue = null;

            this.calculateSku();

            this.updateProductInterface( stopUriUpdate );

            this.updateDisabledOptions();

            optionData = {
                optionId:   +(optionId),
                value:      null,
                hasImage:   this.product.options[optionId].has_image
            };

            this.updateOptionsInterface( optionData );

        } else if (
			optionId &&
			this.product &&
			this.product.options &&
			this.product.options[optionId] &&
			this.product.options[optionId].values
		) {
            // make sure we have everything required to make this work
			if ( !this.product.options[optionId].values[value] ) {
				// that value does not exist for that option

				if (
					this.product.main_options &&
					this.product.main_options[value]
				) {
					// PLOT TWIST: The option value had been for a main_option all along.
					// dun dun DUN
					value = this.getOptionFromMainOption( optionId, value );
				}

			}

			// re-validate
			if ( value && this.product.options[optionId].values[value] ) {
				this.product.options[optionId].selectedValue = +( value );

				this.calculateSku();

				this.updateProductInterface( stopUriUpdate );

				this.updateDisabledOptions();

				optionData = {
					optionId:   +(optionId),
					value:      +(value),
					hasImage:   this.product.options[optionId].has_image,
					gallery:    this.getImagesGallery( this.product.options[optionId].values[value] ),
					gallery360: this.getImagesThreeSixty( value ),
					info:       {
						productName: this.product.name,
						optionName:  this.product.options[optionId].values[value].name
					}
				};

				this.updateOptionsInterface( optionData );

				if ( DEBUG ) {
					console.log( 'zg.product.optionSelected', optionData );
				}

				$( document ).trigger( 'zg.product.optionSelected.' + this.options.namespace, [optionData] );
				this.$element.trigger( 'zg.product.optionSelected', [optionData] );
			}
		} else if ( DEBUG ) {
			console.warn(
				'Error: Product ' + this.product.id + ' is missing option information for option ' + optionId
			);
		}
	};


	/**
	 *
	 *
	 */
	Product.prototype.updateSEO = function () {
		var uriStatus;
		var useReplace;

		if ( this.options.updateUri && this.product.seo ) {
			// Get the current history status
			uriStatus = (( $.uriMgr( { action: 'getStatus' } ) || {} ).status || {} );
			// If the current status was triggered by Product and the pid is equal to the current
			// one we use 'replace' instead of 'load'
			useReplace = !!(
				uriStatus.data &&
				uriStatus.data.trigger === 'Product' &&
				uriStatus.data.pid === this.product.id
			);

			// set up the SEO url
			if ( this.product.url ) {
				$.uriMgr( {
					// with 'load' if the browser does not support History, the new url will be
					// loaded
					action: useReplace ? 'replace' : 'load',
					url:    this.product.url,
					title:  this.product.seo.title,

					//store information to change the current product based on the browser history
					data: {
						trigger: 'Product',
						pid:     this.product.id
					}
				} );
			}

			// setup new product page
			if ( this.product.seo.title ) {
				document.title = this.product.seo.title;
			}

			if ( this.product.seo.meta_description ) {
				$( 'meta[name=description]' ).remove();
				$( 'head' ).append( '<meta name="description" content="' + this.product.seo.meta_description + '">' );
			}
		}
	};


	/**
	 *
	 *
	 */
	Product.prototype.updateURL = function () {
		var currentOptions;
		var newOptions;
		var title;
		var url;
		var $link;
		var $quickbuy;

		if ( this.product ) {
			currentOptions = ( $.uriMgr( { action: 'getStatus' } ).components || {} ).options || [];
			newOptions     = this.getSelectedOptionsArray();

			$link     = $( this.options.elementLink, this.$element );
			$quickbuy = $( '[data-zg-role="quickbuy"]', this.$element );

			if ( this.options.updateUri ) {
				// Update current page uri
				if ( !_.isEqual( currentOptions, newOptions ) ) {

					title = this.product.seo && this.product.seo.title ? this.product.seo.title : null;

					$.uriMgr( {
						action:    'replace',
						applied:   { 'options': newOptions },
						available: ['options'],
						title:     title,

						//store information to change the current product based on the browser
						// history
						data: {
							trigger: 'Product',
							pid:     this.product.id
						}
					} );
				}

				url = document.location.href;
			} else {
				// get updated url with the selected options
				url = $.uriMgr( {
					action:    'getUrl',
					url:       this.product.url,
					applied:   { 'options': newOptions },
					available: ['options']
				} );
			}

			// update quickbuy with selected options
			$quickbuy
				.data( 'selectedOptions', newOptions )
				.attr( 'data-selected-options', newOptions );

			// update product link with selected options
			$link.attr( 'href', url );

			$quickbuy.attr( 'href', url );
		}
	};


	/**
	 * returns the nuber of products in the cart for the SKU selected currently
	 *
	 * @returns {number}
	 */
	Product.prototype.quantityInCart = function () {
		var i;
		var lastCart;
		var quantity = 0;

		if ( this.product ) {
			lastCart = window.getLastCart ? window.getLastCart() : null;

			if ( lastCart && lastCart.products_in_cart.length ) {
				for ( i = 0; i < lastCart.products_in_cart.length; i++ ) {
					if (
						lastCart.products_in_cart[i].product_id == this.product.id &&
						lastCart.products_in_cart[i].sku == this.selectedSku
					) {
						quantity += +(lastCart.products_in_cart[i].quantity);
					}
				}
			}
		}

		return +quantity;
	};

	// PRODUCT PLUGIN DEFINITION
	// =========================

	function Plugin ( option, product ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.product' );
			var options = $.extend(
				{},
				Product.DEFAULTS,
				window.ZG_CONFIG || {},
				$this.data(),
				typeof option === 'object' && option
			);

			product = product || options.product || null;
			delete( options.product );

			if ( !data ) {
				$this.data( 'zg.product', (data = new Product( this, options )) );
			} else if ( typeof option === 'object' ) {
				data.setOptions( options );
			}

			if ( product ) {
				data.setProductInfo( product );
			}
		} );
	}

	$.fn.zg_product             = Plugin;
	$.fn.zg_product.Constructor = Product;



	/**
	 * @method document
	 * @listen document#zg.getProductInfo.productCreated  When product rendered by handlebars (like
	 *     in category page) start the plugin
	 */
	$( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
		var $element = $( element );
		var linkedOptions;

		// if its a product container we initialize the scripts for the linked products
		if ( product && product.attributes && product.attributes.isContainer && product.linked_products ) {
			// several products per page. The linked products shouldn't update the url.
			linkedOptions = $.extend( {}, options || {}, { updateUri: false } );

			for ( var i = 0; i < product.linked_products.length; i++ ) {
				Plugin.call(
					$( element ).find( selector + '[data-id="' + product.linked_products[i].id + '"]' ),
					linkedOptions,
					product.linked_products[i]
				);
			}
		}

		// normal product / product container
		if ( $element.filter( selector ).length ) {
			$element = $element.filter( selector );
		} else {
			$element = $element.find( selector + '[data-id="' + product.id + '"]' );
		}

		Plugin.call( $element, options, product );
	} );

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery, _ ));
